const { Box, styled } = require("@mui/material")

const FilterWrapperButton = styled(Box, { shouldForwardProp: (prop) => prop !== "open" })(({ theme, open }) => ({
	display: "flex",
	flexDirection: "row",
	flexWrap: "nowrap",
	alignContent: "stretch",
	alignItems: "center",
	justifyContent: "space-between",
	padding: theme.spacing(1),
	color: theme.palette.text.secondary,
	fontFamily: "Gilroy-SemiBold",
	cursor: "pointer",
	transition: "0.05s linear",
	fontSize: "14px",
	background: "white",
	height: "100%",

	...(open && {
		borderRadius: "4px 4px 0px 0px",
		borderBottom: `1px solid ${theme.palette.borders.main}`,
	}),
	...(!open && {
		borderRadius: "4px",
		borderBottom: "none",
	}),

	"&:hover": {
		background: "#FAFAFA",
	},
}))

export default FilterWrapperButton
