import { defaultStyles } from "@visx/tooltip"
import Box from "@mui/material/Box"
import Table from "@mui/material/Table"
import TableCell from "@mui/material/TableCell"
import TableRow from "@mui/material/TableRow"
import TableHead from "@mui/material/TableHead"
import TableBody from "@mui/material/TableBody"
import { Typography } from "@mui/material"

const dotSize = 8

function TooltipRow({ fill, unit, data, value }) {
	const tableCellClassName = "txt-secondary p-tb-2 p-lr-3 border-0 font-size-13 opacity-80"

	return (
		<TableRow className="m-b-6 p-4">
			<TableCell className={tableCellClassName}>
				<svg width={dotSize} height={dotSize}>
					<rect fill={fill} width={dotSize} height={dotSize} rx={10} />
				</svg>
			</TableCell>
			<TableCell align="right" className={tableCellClassName}>
				{data.toLocaleString("en")} {unit}
			</TableCell>
			<TableCell className={`${tableCellClassName} txt-transform-capitalize`} align="right">
				{value.slice(0, 5)}
			</TableCell>
		</TableRow>
	)
}

export default function StackedBarTooltip({ tooltipLeft, tooltipTop, tooltipData, TooltipInPortal, colorScale, unit }) {
	const tooltipBarData = tooltipData.bar.data
	const keysToRender = Object.keys(tooltipBarData).filter((x) => x !== "xValue")

	const tooltipStyles = {
		...defaultStyles,
		minWidth: 140,
		borderRadius: ".4rem",
		fontSize: "0.75rem",
	}

	let total = 0
	keysToRender.forEach((key) => (total += tooltipBarData[key]))

	return (
		<TooltipInPortal className="txt-main p-tb-0 p-lr-1-theme border-0 opacity-97 m-0" top={tooltipTop} left={tooltipLeft} style={tooltipStyles}>
			<Table>
				<TableHead className="divider-border">
					<Box>
						<TableCell className="border-0 p-t-6 p-lr-4 p-b-4" colSpan={3}>
							<Typography className="txt-secondary" variant="bold">
								{tooltipBarData.xValue}
							</Typography>
						</TableCell>
					</Box>
				</TableHead>
				<TableBody>
					<Box className="p-tb-1-theme p-lr-0" data-testid="tooltip-y">
						{keysToRender.map((key) => (
							<>{tooltipBarData[key] > 0 && <TooltipRow key={key} value={key} fill={colorScale(key)} data={tooltipBarData[key]} unit={unit} />}</>
						))}
						<TooltipRow value={"Total"} fill={"transparent"} data={total} unit={unit} />
					</Box>
				</TableBody>
			</Table>
		</TooltipInPortal>
	)
}
