import { Grid, useTheme } from "@mui/material"
import Item from "../components/Item"
import { LayoutGrid } from "./LayoutGrid"

export default function LayoutItem({ children, xs = 12, sm = 12, md = 12, lg = 12, xl = 12, elevation = 1, minHeight = 0, outerStyle = {}, innerStyle = {}, order = {} }) {
	const theme = useTheme()
	return (
		<LayoutGrid item xs={xs} sm={sm} md={md} lg={lg} xl={xl} data-testid={"layout-item"} style={{ ...outerStyle }} order={order}>
			<Item elevation={elevation} style={{ minHeight, padding: theme.spacing(3), ...innerStyle }}>
				{children}
			</Item>
		</LayoutGrid>
	)
}
