import { CssBaseline } from "@mui/material"
import { createTheme, StyledEngineProvider, ThemeProvider } from "@mui/material/styles"
import variables from "../styles/colors.scss"

export const palette = {
	type: "light",
	skyBlue: {
		main: variables.skyBlueMain,
		secondary: variables.skyBlueSecondary,
		tertiary: variables.skyBlueTertiary,
		mid: variables.skyBlueMid,
	},
	white: {
		main: variables.whiteMain,
		secondary: variables.whiteSecondary,
		tertiary: variables.whiteTertiary,
	},
	text: {
		main: variables.textMain,
		secondary: variables.textSecondary,
	},
	charts: {
		background: "transparent",
		colorScale: ["#ea9281", "#a998eb", "#5dafee", "#51b8aa", "#64b974", "#f9924d", "#e48baf", "#cd8fd6"],
	},
	borders: {
		main: variables.borderMain,
	},
}

const ThemeWrapper = ({ children }) => {
	const theme = createTheme({
		components: {
			MuiTabs: {
				styleOverrides: {
					root: {
						"& .MuiTab-root": {
							textTransform: "none",
							color: variables.textMain,
							minHeight: "40px",
							padding: "10px 16px",
							minWidth: "inherit",
							maxWidth: "inherit",
							borderBottom: "1px solid #F0F0F0",
							fontFamily: "Gilroy-SemiBold",

							"&.Mui-selected": {
								color: variables.textMain,
							},
						},
						"& .MuiTabs-indicator": {
							backgroundColor: variables.skyBlueSecondary,
						},
					},
				},
			},
			MuiChip: {
				styleOverrides: {
					root: {
						"& .MuiAvatar-root": {
							background: palette.text.secondary,
							color: palette.white.main,
							opacity: 0.5,
							margin: 0,
							height: "40px",
							width: "40px",
							fontSize: "15px",
						},
					},
				},
			},
			MuiListItem: {
				styleOverrides: {
					paper: {
						backgroundColor: palette.white.main,
						color: palette.text.secondary,
					},
					root: {
						"& .MuiListItemButton-root": {
							borderRight: "3px solid transparent",

							"&.Mui-selected": {
								backgroundColor: palette.white.tertiary,
								borderRight: "3px solid",
								borderRightColor: palette.skyBlue.main,

								".MuiListItemIcon-root > svg, .MuiListItemText-root  > .MuiTypography-navItem": {
									color: palette.skyBlue.secondary,
								},
							},

							"&:hover": {
								backgroundColor: palette.white.tertiary,
								borderRightColor: palette.skyBlue.main,
							},
						},
					},
				},
			},
			MuiTableBody: {
				styleOverrides: {
					root: {
						"& .MuiTableCell-root": {
							fontFamily: "Gilroy-Regular",
						},
					},
				},
			},
			MuiDataGrid: {
				styleOverrides: {
					root: {
						//Header Background and Text Color
						"& .MuiDataGrid-columnHeaders": {
							backgroundColor: palette.white.main,
							color: palette.text.main,
						},

						"& .MuiDataGrid-columnSeparator": {
							display: "none",
						},
						//Background color of the row and footer
						"& .MuiDataGrid-cell, .MuiDataGrid-footerContainer, .MuiDataGrid-virtualScrollerContent": {
							backgroundColor: palette.white.main,
						},
						// Cell Color and outline
						"& .MuiDataGrid-cell:focus, .MuiDataGrid-columnHeader:focus": {
							outline: "1px solid" + palette.white.main,
						},
						//Always header icons
						"& .MuiDataGrid-columnHeader .MuiDataGrid-iconButtonContainer": {
							width: "auto",
							visibility: "visible",
						},
						//Sort icon opacity
						"& .MuiDataGrid-columnHeader:not(.MuiDataGrid-columnHeader--sorted) .MuiDataGrid-sortIcon": {
							opacity: 0.5,
						},
						"& .MuiLink-root": {
							color: palette.skyBlue.mid,
						},
					},
				},
			},
			MuiPagination: {
				styleOverrides: {
					root: {
						"& .MuiPaginationItem-root.Mui-selected": {
							background: "rgba(107, 114, 128, 0.5)",
						},
						"& .MuiPaginationItem-text": {
							color: "rgba(107, 114, 128, 1)",
						},
						"& .MuiPaginationItem-root": {
							borderRadius: 3,
						},
						"& .MuiPagination-ul": {
							justifyContent: "center",
						},
						"& .MuiPaginationItem-text.Mui-selected": {
							color: palette.white.main,
						},
					},
				},
			},
		},

		palette: {
			type: "light",
			skyBlue: palette.skyBlue,
			white: palette.white,
			text: palette.text,
			borders: palette.borders,
		},

		typography: {
			bold: {
				fontFamily: "Gilroy-SemiBold",
			},

			h1: {
				fontFamily: "Gilroy-SemiBold",
				lineHeight: "1.5",
				fontSize: "44px",
			},
			h2: {
				fontFamily: "Gilroy-SemiBold",
				lineHeight: "1.25",
				fontSize: "36px",
			},
			h3: {
				fontFamily: "Gilroy-SemiBold",
				lineHeight: "1.25",
				fontSize: "26px",
			},

			h3bold: {
				fontFamily: "Gilroy-SemiBold",
				lineHeight: "1.25",
				fontSize: "26px",
			},

			h4: {
				fontFamily: "Gilroy-SemiBold",
				lineHeight: "1",
				fontSize: "17.5px",
			},

			h4bold: {
				fontFamily: "Gilroy-SemiBold",
				lineHeight: "1.5",
				fontSize: "17.5px",
			},

			h5: {
				fontFamily: "Gilroy-SemiBold",
				lineHeight: "1.5",
				fontSize: "15px",
			},

			h5bold: {
				fontFamily: "Gilroy-SemiBold",
				lineHeight: "1.5",
				fontSize: "15px",
			},

			h6: {
				fontFamily: "Gilroy-SemiBold",
				lineHeight: "1.5",
			},
			body1: {
				fontFamily: "Gilroy-Regular",
				lineHeight: "1.4",
				fontSize: "14px",
				color: palette.text.secondary,
			},
			subtitle1: {
				fontFamily: "Gilroy-Regular",
			},
			subtitle2: {
				fontFamily: "Gilroy-Regular",
			},
			caption: {
				fontFamily: "Gilroy-Regular",
				//lineHeight: '1.5',
				//fontSize: '15px'
			},
			button: {
				fontFamily: "Gilroy-Medium",
				//fontWeight: 900,
			},
			overline: {
				fontFamily: "Gilroy-SemiBold",
			},
			navItem: {
				fontFamily: "Gilroy-Regular",
				fontWeight: "300",
				color: palette.text.secondary,
			},
		},

		transitions: {
			duration: {
				shortest: 150,
				shorter: 200,
				short: 250,
				// most basic recommended timing
				standard: 300,
				// this is to be used in complex animations
				complex: 375,
				// recommended when something is entering screen
				enteringScreen: 225,
				// recommended when something is leaving screen
				leavingScreen: 195,
			},
		},

		shape: {
			borderRadius: 8,
		},

		shadows: ["none", "0px 2px 10px rgba(0, 0, 0, 0.14)", "0px 1px 4px rgba(0, 0, 0, 0.16)"],
	})

	return (
		<StyledEngineProvider injectFirst>
			<ThemeProvider theme={theme}>
				<CssBaseline />
				{children}
			</ThemeProvider>
		</StyledEngineProvider>
	)
}

export default ThemeWrapper
