import { createSvgIcon } from "@mui/material"

export const IcRescue = createSvgIcon(
	<svg width="22" height="22" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path
			d="M2.75 2.20426C2.55109 2.20426 2.36032 2.28355 2.21967 2.42469C2.07902 2.56583 2 2.75725 2 2.95685V13.1813L3.71967 11.4557C3.86032 11.3146 4.05109 11.2353 4.25 11.2353H13.25C13.4489 11.2353 13.6397 11.156 13.7803 11.0148C13.921 10.8737 14 10.6823 14 10.4827V2.95685C14 2.75725 13.921 2.56583 13.7803 2.42469C13.6397 2.28355 13.4489 2.20426 13.25 2.20426H2.75ZM1.15901 1.36038C1.58097 0.936966 2.15326 0.699097 2.75 0.699097H13.25C13.8467 0.699097 14.419 0.936966 14.841 1.36038C15.2629 1.78379 15.5 2.35805 15.5 2.95685V10.4827C15.5 11.0815 15.2629 11.6557 14.841 12.0791C14.419 12.5026 13.8467 12.7404 13.25 12.7404H4.56066L1.78033 15.5303C1.56583 15.7456 1.24324 15.81 0.962987 15.6935C0.682732 15.577 0.5 15.3026 0.5 14.9982V2.95685C0.5 2.35805 0.737053 1.78379 1.15901 1.36038Z"
			fill="#637381"
		/>
	</svg>,
	"IcRescue"
)
