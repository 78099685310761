import { useState } from "react"
import { Box, Container, Typography, useTheme } from "@mui/material"
import CertificateTable from "../components/CertificateTable"
import { DateRangeSelector } from "../components/CertificateTable/DateRangeSelector"
import { usePageTitle } from "../hooks/TitleHook"

const Certificates = () => {
	const [checkedValue, setChecked] = useState("year")
	usePageTitle("BlueHalo - Offset Retirement Certificates")

	const handleChange = (event) => {
		setChecked(event.target.value)
	}

	return (
		<Box>
			<Container sx={{ ml: -3, mb: 1 }}>
				<Typography variant="h3" textAlign="start" className="txt-main m-b-0">
					Offset Retirement Certificates
				</Typography>
				<DateRangeSelector handleChange={handleChange} value={checkedValue} />
			</Container>
			<CertificateTable months={checkedValue === "year" ? 12 : 120} />
		</Box>
	)
}

export default Certificates
