import { Skeleton } from '@mui/material';
import CertificateGrid from './CertificateGrid';


const generateRows = (count, keys) => {
    const rows = [];
    let id = 1;
    for (let i = 0; i < count; i++) {
        const row = {};
        keys.forEach(key => {
            row[key] = `${key} ${i}`;
        }
        );
        rows.push({ ...row, id: id++ });
    }
    return rows;
}

const formateColumns = (names) => {
    return names.map(name => (
        {
            field: name,
            headerName: name,
            sortable: true,
            editable: false,
            width: 300,
            renderCell: () => {
                return (
                    <Skeleton animation="wave" variant="text" width='60%' />
                )
            }
        }
    ));

}

const SkeletonGrid = ({headers, count}) => {

    const gridData = {
        columns: formateColumns(headers),
        rows: generateRows(count, headers),
    }
    return (
        <CertificateGrid gridData={gridData} pageSize={count} />
    )
}

export default SkeletonGrid