import { Box, ListItem, ListItemButton, ListItemIcon, ListItemText, Typography } from "@mui/material"
import ExpandLess from "@mui/icons-material/ExpandLess"
import ExpandMore from "@mui/icons-material/ExpandMore"
import CustomSubMenuItem from "./CustomSubMenuItem"
import LinkType from "./LinkType"
import styles from "./index.module.scss"

const CustomMenuItem = ({ name, link, icon, submenu, external, expand, handleClick }) => {
	const getExpanded = (name) => {
		return expand[name]
	}

	const checkSelected = (to) => {
		return window.location.pathname === to
	}

	return (
		<Box component={"div"} key={name}>
			<LinkType link={link} external={external}>
				<ListItem className="display-block" key={name} disablePadding>
					<ListItemButton
						data-testid={"custom-menu-item-button"}
						data-selected={checkSelected(link)}
						className={styles["sidebar-button"]}
						selected={checkSelected(link)}
						onClick={() => handleClick(name)}
					>
						{icon && (
							<ListItemIcon data-testid="custom-menu-item-icon" className={styles["sidebar-icon"]}>
								{icon}
							</ListItemIcon>
						)}
						<ListItemText className="font-size-16" primary={<Typography variant="navItem">{name}</Typography>} />
						{submenu ? getExpanded(name) ? <ExpandLess className="txt-secondary" /> : <ExpandMore className="txt-secondary" /> : null}
					</ListItemButton>
				</ListItem>
			</LinkType>
			{submenu ? submenu.map((s, i) => <CustomSubMenuItem key={i} handleClick={handleClick} expand={getExpanded(name)} {...s} />) : null}
		</Box>
	)
}

export default CustomMenuItem
