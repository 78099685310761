import { useState, useEffect } from "react"
import TableCell from "@mui/material/TableCell"
import TableHead from "@mui/material/TableHead"
import TableRow from "@mui/material/TableRow"
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp"
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown"
import styles from "../index.module.scss"
import { Box } from "@mui/material"

const TableHeaderCell = ({ sortOrder, title, orderKey, canSort, sortKey, headerAlign, handleSortClick, disableSort }) => {
	const [hovering, setHovering] = useState(false)
	const canSortCell = !disableSort && canSort
	const selected = orderKey === sortKey
	const isInteracting = (selected || hovering) && canSort
	const align = headerAlign ?? "left"
	const leftPaddingForSortIcon = align === "right" ? "p-l-8" : ""

	const sortIcon =
		sortOrder === "asc" ? (
			<KeyboardArrowUpIcon className={`${styles["sort-icon-style"]} ${leftPaddingForSortIcon}`} />
		) : (
			<KeyboardArrowDownIcon className={`${styles["sort-icon-style"]} ${leftPaddingForSortIcon}`} />
		)

	return (
		<TableCell
			onMouseOver={() => setHovering(true)}
			onMouseLeave={() => setHovering(false)}
			key={title}
			data-testid="table-cell-header"
			data-align={align}
			className={`user-select-none position-relative font-semi-bold p-tb-2-theme p-l-2-theme min-w-100-px border-0 ${canSortCell ? "cursor-pointer" : null} p-r-2-theme`}
			align={align}
			onClick={() => {
				if (canSortCell) handleSortClick(sortKey)
			}}
		>
			{title}
			{isInteracting ? <Box className={`${hovering && !selected ? "opacity-50" : ""}`}>{sortIcon}</Box> : null}
		</TableCell>
	)
}

export default function TableHeader({ columns = [], onSortUpdate, defaultSortColumn, disableSort = true, showBorder = false }) {
	const [orderKey, setOrderKey] = useState(defaultSortColumn ? defaultSortColumn : columns.length ? columns[0].sortKey : null)
	const [sortOrder, setSortOrder] = useState("desc")

	const handleSortClick = (column) => {
		let newSortOrder = sortOrder === "asc" ? "desc" : "asc"

		if (column === orderKey) {
			setSortOrder(newSortOrder)
		} else {
			newSortOrder = "desc"
			setOrderKey(column)
			setSortOrder(newSortOrder)
		}

		onSortUpdate(column, newSortOrder)
	}

	if (columns.length === 0) return null

	return (
		<TableHead style={{ maxHeight: "100px !important" }}>
			<TableRow className={showBorder ? "divider-border" : ""}>
				{columns.map((x) => (
					<TableHeaderCell sortOrder={sortOrder} orderKey={orderKey} handleSortClick={handleSortClick} disableSort={disableSort} {...x} />
				))}
			</TableRow>
		</TableHead>
	)
}
