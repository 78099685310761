import { useEffect, useState } from "react"
import { useMsal } from "@azure/msal-react"
import { InteractionRequiredAuthError } from "@azure/msal-browser"
import { loginRequest } from "../authConfig"

// Use MSAL and the API scope to fetch a user's bearer token. If this fails silently, then prompt a user to log back in.
export function useAccessToken() {
	const { instance, accounts } = useMsal()
	const [accessToken, setAccessToken] = useState(null)

	useEffect(() => {
		if (accounts.length > 0) {
			const request = {
				scopes: loginRequest.scopes,
				account: accounts[0],
			}
			instance
				.acquireTokenSilent(request)
				.then((response) => {
					setAccessToken(response.accessToken)
				})
				.catch((error) => {
					if (error instanceof InteractionRequiredAuthError) {
						console.log(error)
						instance.acquireTokenRedirect(request).then((response) => {
							setAccessToken(response.accessToken)
						})
					}
				})
		}
	}, [])
	return accessToken
}
