import Certificates from "../pages/Certificates"
import Dashboards from "../pages/Dashboards"
import Portfolio from "../pages/Portfolio"
import Emissions from "../pages/Reports/Emissions"
import Retirements from "../pages/Reports/Retirements"
import SDG from "../pages/Reports/SDG"
import NotFound from "../pages/NotFound"

import { ARTICLE1_ENDPOINT, ARTICLE2_ENDPOINT, ARTICLE3_ENDPOINT, ARTICLE4_ENDPOINT } from "./Constants"
import Article1 from "../pages/articles/Article1"
import Article2 from "../pages/articles/Article2"
import Article3 from "../pages/articles/Article3"
import Article4 from "../pages/articles/Article4"
import CostCentres from "../pages/Reports/CostCentres"
import EmissionsLog from "../pages/Reports/EmissionsLog"

export const routeConfig = [
	{ path: "/", element: <Dashboards />, showHeader: true },
	{ path: "/reports/retirements", element: <Retirements />, showHeader: true },
	{ path: "/reports/emissions", element: <Emissions />, showHeader: true },
	{ path: "/reports/sdg", element: <SDG /> },
	{ path: "/reports/cost-centres", element: <CostCentres />, showHeader: true },
	{ path: "/reports/emissions-log", element: <EmissionsLog /> },
	{ path: "/portfolio", element: <Portfolio /> },
	{ path: "/certificates", element: <Certificates /> },
	{ path: ARTICLE1_ENDPOINT, element: <Article1 /> },
	{ path: ARTICLE2_ENDPOINT, element: <Article2 /> },
	{ path: ARTICLE3_ENDPOINT, element: <Article3 /> },
	{ path: ARTICLE4_ENDPOINT, element: <Article4 /> },
	{ path: "*", element: <NotFound /> },
]
