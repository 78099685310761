import { ACTIVITY_CHART_CONFIG, EMISSIONS_OVERVIEW_ENDPOINT, LATEST_API_VERSION } from "../../../util/Constants"
import { getAllEmissionsByKeyForBar } from "../../../lib/convertions/EmissionsDataSet"
import VisxChartWrapper from "../../ChartsVisX/shared/VisxChartWrapper"
import StackedBar from "../../ChartsVisX/ChartType/StackedBar/StackedBar"
import { palette } from "../../../theme"
import { useModeList } from "../../../hooks/ModeHook"
import { useTheme } from "@mui/system"
import { setQueryParameters } from "../../../pages/sub-components/SetQueryParameters"

const customStackSortOrder = (a, b) => ACTIVITY_CHART_CONFIG[b.toLowerCase()].priority - ACTIVITY_CHART_CONFIG[a.toLowerCase()].priority

const barColorsTransform = (colors, keys) => {
	return keys.map((x) => ACTIVITY_CHART_CONFIG[x.toLowerCase()].color)
}

const AllEmissionsByMode = () => {
	const theme = useTheme()
	const modes = useModeList()
	const { partners } = setQueryParameters()

	const queryData = { method: "get", url: EMISSIONS_OVERVIEW_ENDPOINT, params: { interval: "month", "api-version": LATEST_API_VERSION, partners } }

	return (
		<VisxChartWrapper spinnerMargin={theme.spacing(35)} queryData={queryData} dataTransformFunction={(data) => getAllEmissionsByKeyForBar(data.data, "date", "emissions.total", modes)}>
			{({ transformedData, width }) => (
				<StackedBar
					{...{
						width,
						height: width / 3,
						barColors: palette.charts.colorScale,
						title: "Emission & offset volumes",
						subTitle: "Data source: BlueHalo",
						unit: "tCO2e",
						data: transformedData,
						showTotals: true,
						yLabel: "tCO2e",
						customStackSortOrder,
						barColorsTransform,
					}}
				/>
			)}
		</VisxChartWrapper>
	)
}
export default AllEmissionsByMode
