import Box from "@mui/material/Box"
import Typography from "@mui/material/Typography"
import { palette } from "../../../theme"

export default function GraphTitle({ title = null, subTitle = null }) {
	return (
		<Box>
			{title && (
				<Typography className="m-0 txt-main" variant="h2" fontSize="1.25em">
					{title}
				</Typography>
			)}
			{subTitle && (
				<Typography className="m-0 txt-secondary" variant="h3" fontSize="0.85em">
					{subTitle}
				</Typography>
			)}
		</Box>
	)
}
