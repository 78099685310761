import { useQueryEndpoint } from "../../hooks/QueryHooks"
import { EMISSIONS_ENDPOINT, RETIREMENT_OFFSET_ENDPOINT, NET_POSITION_ENDPOINT, LATEST_API_VERSION, EMISSIONS_OVERVIEW_ENDPOINT } from "../../util/Constants"
import { isResponsesLoaded } from "../../util/util"
import { DisplaySingleModeTable } from "./displayTable"

import Table from "@mui/material/Table"
import TableBody from "@mui/material/TableBody"
import TableCell from "@mui/material/TableCell"
import TableHead from "@mui/material/TableHead"
import TableRow from "@mui/material/TableRow"
import TableFooter from "@mui/material/TableFooter"
import { setQueryParameters } from "../sub-components/SetQueryParameters"

export const EmissionsWithNetPositiveEndpoint = () => {
	const body = setQueryParameters("POST")
	const queryString = setQueryParameters()

	const res1 = useQueryEndpoint(EMISSIONS_OVERVIEW_ENDPOINT, null, "GET", { "api-version": LATEST_API_VERSION, interval: "month", ...queryString })
	const res2 = useQueryEndpoint(RETIREMENT_OFFSET_ENDPOINT, body)
	const res3 = useQueryEndpoint(NET_POSITION_ENDPOINT, null, "GET", queryString)

	if (isResponsesLoaded([res1, res2, res3])) {
		const emissions = res1.data?.data?.emissions
		let emissionsTotal = 0
		const emissionsList = emissions?.map((emission, i) => {
			emissionsTotal += emission.totalEmissions
			return (
				<TableRow key={`${emission.date}-${i}`}>
					<TableCell>{emission.date}</TableCell>
					<TableCell>{emission.totalEmissions}</TableCell>
					<TableCell>{emission.partnerCode}</TableCell>
				</TableRow>
			)
		})

		const offsets = res2.data?.data?.retirements
		let retirementsTotal = 0
		const offsetsList = offsets?.map((offset, i) => {
			retirementsTotal += offset.emissionValue
			return (
				<TableRow key={i}>
					<TableCell>{offset.retirementDate}</TableCell>
					<TableCell>{offset.emissionValue}</TableCell>
					<TableCell>{offset.retirementMonth}</TableCell>
				</TableRow>
			)
		})

		const netPositions = res3.data?.data?.netPositions

		const netEmissionsList = (
			<TableBody>
				{netPositions.map((netPosition, j) => {
					return (
						<TableRow key={`${j}`}>
							<TableCell>{netPosition.netPositionDate}</TableCell>
							<TableCell>{netPosition.netPosition}</TableCell>
							<TableCell>{netPosition.netPositionMonth}</TableCell>
						</TableRow>
					)
				})}
			</TableBody>
		)

		return (
			<>
				<h3>Emissions</h3>
				<h4>{EMISSIONS_ENDPOINT}</h4>
				<TableCell style={{ verticalAlign: "top" }}>
					<Table>
						<TableHead>
							<TableRow>
								<TableCell>Date</TableCell>
								<TableCell>Total Emissions</TableCell>
								<TableCell>Partner</TableCell>
							</TableRow>
						</TableHead>
						<TableBody>{emissionsList}</TableBody>
						<TableFooter>
							<TableRow>
								<TableCell>Total:</TableCell>
								<TableCell>{emissionsTotal}</TableCell>
							</TableRow>
						</TableFooter>
					</Table>
				</TableCell>

				<h3>Retirements</h3>
				<h4>{RETIREMENT_OFFSET_ENDPOINT}</h4>
				<Table>
					<TableHead>
						<TableRow>
							<TableCell>Date</TableCell>
							<TableCell>Emissions</TableCell>
							<TableCell>Month</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>{offsetsList}</TableBody>
					<TableFooter>
						<TableRow>
							<TableCell>Total:</TableCell>
							<TableCell>{retirementsTotal}</TableCell>
						</TableRow>
					</TableFooter>
				</Table>

				<h3>Net Position</h3>
				<h4>{NET_POSITION_ENDPOINT}</h4>
				<Table key={`net-position`}>
					<TableHead>
						<TableRow>
							<TableCell>Date</TableCell>
							<TableCell>Net</TableCell>
							<TableCell>Month</TableCell>
						</TableRow>
					</TableHead>
					{netEmissionsList}
				</Table>
			</>
		)
	}
	return <>Loading...</>
}
