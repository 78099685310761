import { Box, Collapse, ListItem, ListItemButton, ListItemIcon, ListItemText, Typography } from "@mui/material"
import { Link as RouterLink } from "react-router-dom"
import styles from "./index.module.scss"

const CustomSubMenuItem = ({ expand, name, link, icon, handleClick }) => {
	const checkSelected = (to) => {
		return window.location.pathname === to
	}
	return (
		<Box data-testid={"custom-submenu-item"} component={"div"} key={name}>
			<Collapse in={expand} timeout="auto" unmountOnExit>
				<RouterLink className="txt-decoration-none" to={link}>
					<ListItem className="display-block" key={name} disablePadding>
						<ListItemButton data-testid={"submenu-button"} sx={{ pl: 5 }} selected={checkSelected(link)} onClick={() => handleClick(name)} disableRipple>
							<ListItemIcon className={styles["sidebar-icon"]}>{icon}</ListItemIcon>
							<ListItemText className="font-size-16" primary={<Typography variant="navItem">{name}</Typography>} />
						</ListItemButton>
					</ListItem>
				</RouterLink>
			</Collapse>
		</Box>
	)
}

export default CustomSubMenuItem
