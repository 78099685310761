import { Skeleton, Stack } from "@mui/material"

const SkeletonRetiredCerts = () => {
	return (
		<Stack spacing={0.5} sx={{ alignItems: "start" }}>
			<Skeleton animation="wave" variant="text" width="80%" />
			<Skeleton animation="wave" variant="text" width="80%" />
			<Skeleton animation="wave" variant="text" width="70%" />
			<Skeleton animation="wave" variant="text" width="80%" />
			<Skeleton animation="wave" variant="text" width="40%" />
			<Skeleton animation="wave" variant="text" width="40%" />
		</Stack>
	)
}

export default SkeletonRetiredCerts
