// CUSTOMER_MODE_ENDPOINT
import { useQueryEndpoint } from "../../hooks/QueryHooks"
import { CUSTOMER_MODE_ENDPOINT, LATEST_API_VERSION } from "../../util/Constants"
import { setQueryParameters } from "../sub-components/SetQueryParameters"

import Table from "@mui/material/Table"
import TableBody from "@mui/material/TableBody"
import TableCell from "@mui/material/TableCell"
import TableHead from "@mui/material/TableHead"
import TableRow from "@mui/material/TableRow"

export const CustomerModeEndpoint = () => {
	const params = setQueryParameters("POST")
	const { data, error, loaded } = useQueryEndpoint(CUSTOMER_MODE_ENDPOINT, null, "GET", { "api-version": LATEST_API_VERSION, ...params })

	if (loaded && !error) {
		const modes = data
		const modesKeys = Object.keys(modes.data)
		const freightKeys = ["passenger", "freight"]

		return (
			<>
				<h2>Get User modes</h2>
				<h3>{CUSTOMER_MODE_ENDPOINT}</h3>
				<Table>
					<TableHead>
						<TableRow>
							{modesKeys.map((mode) => (
								<>
									{freightKeys.map((freightKey) => (
										<TableCell key={`label${mode}${freightKey}`}>
											{mode} - {freightKey}
										</TableCell>
									))}
								</>
							))}
						</TableRow>
					</TableHead>
					<TableBody>
						<TableRow>
							{modesKeys.map((mode, i) => (
								<>
									{freightKeys.map((freightKey, j) => (
										<TableCell key={`${i} ${j}`}>
											<input type="checkbox" disabled checked={modes.data[mode][freightKey]} />
										</TableCell>
									))}
								</>
							))}
						</TableRow>
					</TableBody>
				</Table>
			</>
		)
	}
}
