import { createSvgIcon } from "@mui/material/utils"

export const IcReport = createSvgIcon(
	<svg width="24" height="24" viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg">
		<g clipPath="url(#clip0_1008_5986)">
			<path
				d="M6.68721 2.55553C6.85288 2.93648 6.67942 3.38006 6.29978 3.5463C5.28923 3.98881 4.40209 4.67298 3.71591 5.53901C3.02974 6.40504 2.56544 7.42655 2.36359 8.51423C2.16174 9.60191 2.22849 10.7227 2.55801 11.7785C2.88753 12.8343 3.46979 13.793 4.25387 14.5709C5.03796 15.3487 6 15.922 7.05588 16.2406C8.11177 16.5592 9.22935 16.6133 10.3109 16.3984C11.3925 16.1834 12.4051 15.7059 13.2603 15.0075C14.1155 14.3091 14.7871 13.4112 15.2165 12.3922C15.3779 12.0093 15.8179 11.8302 16.1994 11.9921C16.5809 12.154 16.7594 12.5956 16.5981 12.9784C16.0732 14.2238 15.2523 15.3213 14.2071 16.1749C13.1619 17.0285 11.9243 17.6122 10.6024 17.8749C9.28043 18.1376 7.91449 18.0714 6.62397 17.682C5.33344 17.2926 4.15761 16.592 3.19929 15.6413C2.24096 14.6906 1.52932 13.5188 1.12657 12.2283C0.723821 10.9379 0.642233 9.56807 0.888937 8.23868C1.13564 6.90929 1.70313 5.66078 2.54178 4.6023C3.38044 3.54382 4.46472 2.70761 5.69984 2.16677C6.07948 2.00053 6.52155 2.17458 6.68721 2.55553Z"
				fill="#637381"
			/>
			<path
				d="M8.46967 1.69974C8.61032 1.5586 8.80109 1.47931 9 1.47931C10.0834 1.47931 11.1562 1.69344 12.1571 2.10947C13.1581 2.5255 14.0675 3.13528 14.8336 3.904C15.5997 4.67272 16.2074 5.58533 16.622 6.58971C17.0366 7.5941 17.25 8.67059 17.25 9.75772C17.25 10.1734 16.9142 10.5103 16.5 10.5103H9C8.58579 10.5103 8.25 10.1734 8.25 9.75772V2.23189C8.25 2.03229 8.32902 1.84087 8.46967 1.69974ZM9.75 3.02642V9.00514H15.7082C15.6379 8.37403 15.4793 7.75472 15.2362 7.16571C14.897 6.34395 14.3998 5.59727 13.773 4.96831C13.1462 4.33936 12.4021 3.84045 11.5831 3.50006C10.9961 3.25608 10.3789 3.09698 9.75 3.02642Z"
				fill="#637381"
			/>
		</g>
		<defs>
			<clipPath id="clip0_1008_5986">
				<rect width="18" height="18.062" fill="white" transform="translate(0 0.726746)" />
			</clipPath>
		</defs>
	</svg>,
	"IcReport"
)
