import { AuthenticatedTemplate, MsalAuthenticationTemplate, UnauthenticatedTemplate } from "@azure/msal-react"
import { loginRequest } from "../../authConfig"
import { InteractionType } from "@azure/msal-browser"
import { Typography } from "@mui/material"
import { Box, styled } from "@mui/system"
import bluehaloLogo from "../../assets/logos/bluehalo-tem.png"

const LoadingContent = styled(Box)(({ theme }) => ({
	maxWidth: "250px",
}))

export const Loading = () => {
	return (
		<Box className="flex-row justify-center align-center" width={"100vw"} height={"100vh"}>
			<LoadingContent className="animate-fade-in-out">
				<Box>
					<img src={bluehaloLogo} width="100%" />
				</Box>
				<Box>Authentication in progress</Box>
			</LoadingContent>
		</Box>
	)
}

export const ErrorComponent = ({ error }) => {
	return <Typography variant="h5">An Error Occurred: {error.errorCode}</Typography>
}

const ProtectedPage = ({ children }) => {
	return (
		<>
			<UnauthenticatedTemplate>
				<MsalAuthenticationTemplate interactionType={InteractionType.Redirect} authenticationRequest={loginRequest} errorComponent={ErrorComponent} loadingComponent={Loading}>
					{children}
				</MsalAuthenticationTemplate>
			</UnauthenticatedTemplate>

			<AuthenticatedTemplate>{children}</AuthenticatedTemplate>
		</>
	)
}

export default ProtectedPage
