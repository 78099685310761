import { useState } from "react"
import { List } from "@mui/material"
import CustomMenuItem from "./CustomMenuItem"

const DrawerItems = ({ sideBarList }) => {
	const [expand, setExpand] = useState({})

	const handleClick = (name) => {
		const e = { ...expand }
		e[name] = !e[name]
		setExpand(e)
	}

	return (
		<List data-testid="list">
			{sideBarList.map((m, i) => (
				<CustomMenuItem key={i} expand={expand} handleClick={handleClick} {...m} />
			))}
		</List>
	)
}

export default DrawerItems
