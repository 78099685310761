import * as React from "react"
import Table from "@mui/material/Table"
import TableBody from "@mui/material/TableBody"
import { Box } from "@mui/material"
import styles from "../../index.module.scss"
import TableHeader from "../../TableHeader/TableHeader"
import RowWithNestedRows from "../../TableRows/RowWithNestedRows/RowWithNestedRows"
import { sortAlphabetically } from "../../../../lib/SortHelper"

export const getAllNestedKeys = (rows, sortBy = null) => {
	let allKeys = []
	rows.forEach((row) => {
		allKeys = [...allKeys, ...Object.keys(row.value).filter((x) => !["name", "total"].includes(x))]

		if (row.childrenValues.length) allKeys = [...allKeys, ...getAllNestedKeys(row.childrenValues)]
	})

	let keySet = [...new Set(allKeys)]
	if (sortBy) keySet.sort(sortBy)
	return keySet
}

export default function ExpandableTable({ rows, sortFunction = null }) {
	const [expandedHashKeys, setExpandedKeys] = React.useState({})
	const tableKeys = getAllNestedKeys(rows, sortFunction ?? sortAlphabetically)
	const tableKeysHeader = tableKeys.map((x) => {
		return { title: x, canSort: false, headerAlign: "right", cellAlign: "right" }
	})

	const expandHashKey = (hashKey) => {
		let newExpandedKey = { ...expandedHashKeys }
		newExpandedKey[hashKey] = !expandedHashKeys[hashKey]
		setExpandedKeys(newExpandedKey)
	}

	if (!rows.length) return <Box className="txt-align-left p-lr-2-theme p-tb-1-theme">No Data Available</Box>

	return (
		<Box data-testid={"table-container"} id={styles["expanded-table-parent"]} className="flex-row nowrap justify-start align-stretch m-t-1-theme">
			<Box className={`${styles["name-table-container"]} align-self-auto`}>
				<Table>
					<TableHeader columns={[{ title: "Name", canSort: false }]} />
					<TableBody>
						{rows.map((row) => (
							<RowWithNestedRows
								indentRow={true}
								key={row.hashKey}
								hashKey={row.hashKey}
								expandedHashKeys={expandedHashKeys}
								expandHashKey={expandHashKey}
								childrenValues={row.childrenValues}
								value={row.value}
								columns={[{ title: "name" }]}
								showIcon={true}
							/>
						))}
					</TableBody>
				</Table>
			</Box>
			<Box className={`${styles["center-table-container"]} border-col-light`}>
				<Table>
					<TableHeader columns={tableKeysHeader} />
					<TableBody>
						{rows.map((row, i) => (
							<RowWithNestedRows
								key={row.hashKey}
								hashKey={row.hashKey}
								expandedHashKeys={expandedHashKeys}
								expandHashKey={expandHashKey}
								childrenValues={row.childrenValues}
								value={row.value}
								columns={tableKeysHeader}
							/>
						))}
					</TableBody>
				</Table>
			</Box>

			<Box className={`${styles["total-table-container"]} align-self-auto`}>
				<Table>
					<TableHeader columns={[{ title: "Total", canSort: false, headerAlign: "right" }]} />
					<TableBody>
						{rows.map((row) => (
							<RowWithNestedRows
								key={row.hashKey}
								hashKey={row.hashKey}
								expandedHashKeys={expandedHashKeys}
								expandHashKey={expandHashKey}
								childrenValues={row.childrenValues}
								value={row.value}
								columns={[{ title: "total", cellAlign: "right" }]}
							/>
						))}
					</TableBody>
				</Table>
			</Box>
		</Box>
	)
}
