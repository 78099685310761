import { useTheme } from "@mui/system"
import EmissionsWithNetPositive from "../../components/Charts/Wireups/EmissionsWithNetPositive"
import RetirementCountries from "../../components/Charts/Wireups/RetirementCountries"
import RetirementProjectType from "../../components/Charts/Wireups/RetirementProjectType"
import GraphTitle from "../../components/ChartsVisX/shared/GraphTitle"
import { usePageTitle } from "../../hooks/TitleHook"
import LayoutItem from "../../layoutBuilder/LayoutItem"
import LayoutWrapper from "../../layoutBuilder/LayoutWrapper"

const Retirements = () => {
	usePageTitle("BlueHalo - Retirements")
	const theme = useTheme()

	const layoutItemProps = {
		lg: 6,
		xl: 6,
		minHeight: 220,
		elevation: 0,
		innerStyle: { padding: 0 },
		outerStyle: { padding: 0, marginTop: theme.spacing(3) },
	}

	return (
		<LayoutWrapper style={{ padding: theme.spacing(2) }}>
			<LayoutWrapper>
				<LayoutItem>
					<GraphTitle title="Offset retirement activity & net position" subTitle={"Data source: BlueHalo"} />
					<LayoutWrapper style={{ padding: theme.spacing(2) }}>
						<LayoutItem {...layoutItemProps}>
							<RetirementProjectType />
						</LayoutItem>
						<LayoutItem {...layoutItemProps}>
							<RetirementCountries />
						</LayoutItem>
					</LayoutWrapper>
				</LayoutItem>
				<LayoutItem minHeight={400}>
					<EmissionsWithNetPositive />
				</LayoutItem>
			</LayoutWrapper>
		</LayoutWrapper>
	)
}

export default Retirements
