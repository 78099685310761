export function getSeries(name, data, stack) {
	return {
		name: name,
		data: data ? data : [],
		stack: stack ? stack : "group1",
	}
}

export function getDrillabelseries(name, data) {
	return {
		name: name,
		drilldown: name,
		y: data ? data : 0,
	}
}

export function formatY(name, row) {
	return {
		name: name,
		y: row,
	}
}

export function formatD(id, data) {
	return {
		name: id,
		id: id,
		data: data,
	}
}

export function getDataSet(name, rows) {
	return { name, rows }
}

export function formatDrillDown(data = [], labels = []) {
	if (!data) return []

	let formatedData = []
	let series = []
	for (const d of data) {
		if (!d?.data) continue
		let subSeries = d.data
		for (let i = 0; i < labels.length; i++) {
			let id = labels[i]
			formatedData = subSeries.map((d) => formatY(d.name, d.rows[i]))
			series.push(formatD(id, formatedData))
		}
	}

	return series
}

const Sum = (number = []) => number.reduce((partialSum, a) => partialSum + a, 0)

export function filterSeries(data = [], key = "", value = "") {
	return data.filter((i) => i[key] === value)
}

export function formatSeries2(data = [], labels = []) {
	let series = []
	let sumTotal = 0
	for (let i = 0; i < labels.length; i++) {
		const label = labels[i]
		let total = []
		total = data.map((d) => d.rows[i])

		sumTotal = Sum(total)
		if (sumTotal === 0) continue
		series.push(getDrillabelseries(label, sumTotal))
	}

	return series
}

export function formatSeries(data = []) {
	let series = []
	if (!data) return []
	series = data.map((i) => getSeries(i.name, i.rows))
	return series
}

// ------------ CONFIGURE Series Functions ------

export function setSeriesConfiguration(series, config) {
	for (const key in config) {
		series[key] = config[key]
	}
}

export function configureSeries(series = [], configurations = []) {
	for (const c of configurations) {
		let filterSeries = series.filter((s) => s.name === c.seriesName)[0]
		if (filterSeries) setSeriesConfiguration(filterSeries, c.config)
	}
}
