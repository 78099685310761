import * as React from "react"
import TableCell from "@mui/material/TableCell"
import { Box, TableRow } from "@mui/material"
import styles from "../../index.module.scss"

const formatString = (str) => {
	if (typeof str == "number") return str.toFixed(2)
	return str
}

export default function RowWithChild({ hashKey, value, childComponent, columns = null }) {
	const [isExpanded, setIsExpanded] = React.useState(false)
	const columnHeaders =
		columns ??
		Object.keys(value).map((title) => {
			return { title }
		})

	return (
		<>
			<TableRow onClick={() => setIsExpanded(!isExpanded)} data-testid={`row-${hashKey}`} className={`display-table-row divider-border ${childComponent ? "cursor-pointer" : ""}`}>
				{columnHeaders.map((column) => (
					<TableCell
						className={`font-semi-bold ${styles["expandable-table-cell"]} p-2-theme user-select-none`}
						key={`row-${hashKey}-cell-${column.title}`}
						data-testid={`row-${hashKey}-cell-${column.title}`}
						data-align={column.cellAlign ?? "left"}
						align={column.cellAlign ?? "left"}
					>
						<Box className={"txt-ellipsis"}>{formatString(value[column.cellKey ?? column.title] ?? "-")}</Box>
					</TableCell>
				))}
			</TableRow>
			{isExpanded && childComponent && (
				<TableRow className="bg-grey">
					<TableCell colSpan={columns.length}>{childComponent}</TableCell>
				</TableRow>
			)}
		</>
	)
}
