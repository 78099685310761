/* RETIREMENTS_BY_PROJECT_TYPE_ENDPOINT */
import { useQueryEndpoint } from "../../hooks/QueryHooks"
import { RETIREMENTS_BY_PROJECT_TYPE_ENDPOINT } from "../../util/Constants"
import { setQueryParameters } from "../sub-components/SetQueryParameters"
import { DisplaySingleModeTable } from "./displayTable"

export const RetirementsByProjectType = () => {
	const body = setQueryParameters("POST")

	const { data, error, loaded } = useQueryEndpoint(RETIREMENTS_BY_PROJECT_TYPE_ENDPOINT, body)

	if (loaded && !error) {
		const retirementsData = data.data.retirements ?? []

		return <DisplaySingleModeTable data={retirementsData} title={"Retirements by Project Type"} caption={`${RETIREMENTS_BY_PROJECT_TYPE_ENDPOINT}`} totaller="emissionValue" />
	}
	return <>Loading...</>
}
