import { ClickAwayListener, Paper } from "@mui/material"
import { Box } from "@mui/system"
import moment from "moment"
import { getStartEndDate, getStartEndUnit, getStartEndFinancialYear } from "../../../util/util"

export const dateRangeRows = [
	{ title: "This month", dateRange: getStartEndUnit("month"), lastRange: getStartEndUnit("month", -1) },
	{ title: "Last month", dateRange: getStartEndUnit("month", -1), lastRange: getStartEndUnit("month", -2) },
	{ title: "This quarter", dateRange: getStartEndUnit("Q"), lastRange: getStartEndUnit("Q", -1) },
	{ title: "Last quarter", dateRange: getStartEndUnit("Q", -1), lastRange: getStartEndUnit("Q", -2) },
	{ title: "This year", dateRange: getStartEndUnit("year"), lastRange: getStartEndUnit("year", -1) },
	{ title: "Last year", dateRange: getStartEndUnit("year", -1), lastRange: getStartEndUnit("year", -2) },
	{ title: "Last 6 months", dateRange: getStartEndDate(6), lastRange: getStartEndDate(6, 6) },
	{ title: "Last 12 months", dateRange: getStartEndDate(12), lastRange: getStartEndDate(12, 12) },
	{ title: "This financial year", dateRange: getStartEndFinancialYear(), lastRange: getStartEndFinancialYear(-1) },
	{ title: "Last financial year", dateRange: getStartEndFinancialYear(-1), lastRange: getStartEndFinancialYear(-2) },
]

export default function QuickOptionPicker({ onClickAway, open, updateDate = null }) {
	const setDate = (index) => {
		const dateFromMoment = moment(dateRangeRows[index].dateRange.dateFrom)
		const dateToMoment = moment(dateRangeRows[index].dateRange.dateTo)
		const data = { dateFrom: dateRangeRows[index].dateRange.dateFrom, dateTo: dateRangeRows[index].dateRange.dateTo, daysBetween: Math.ceil(dateToMoment.diff(dateFromMoment, "days")) }
		if (updateDate) updateDate(data, dateRangeRows[index].title)
	}

	if (!open) return null

	return (
		<ClickAwayListener onClickAway={onClickAway}>
			<Paper className="overflow-hidden" data-testid={"option-picker"} style={{ width: "170px", maxWidth: "100vw" }}>
				{dateRangeRows.map((row, index) => (
					<Box
						key={`quick-date-option-${index}`}
						className={`txt-align-left font-semi-bold p-tb-1-theme p-lr-2-theme cursor-pointer txt-secondary bg-white bg-hover-grey ${
							index + 1 !== dateRangeRows.length ? "border-b-1 border-col-light" : ""
						}`}
						data-testid={"option"}
						onClick={() => setDate(index)}
						height={35}
					>
						{row.title}
					</Box>
				))}
			</Paper>
		</ClickAwayListener>
	)
}
