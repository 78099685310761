import { trimArrayOfKeyValueObjects } from "../../util/util"

function getDistinct(arr = []) {
	return [...new Set(arr)]
}

function getDataSet(name, rows) {
	return { name, rows }
}

function toNegativeNum(num) {
	if (!num) return 0
	return -Math.abs(num)
}

export const ALL_MODES = ["air", "accommodation", "road", "sea", "rail"]

const getDataLabels = (emissions, modes) => {
	let dates = []
	modes.forEach((mode) => {
		if (emissions[mode]?.length) {
			dates = emissions[mode].map((i) => i.date)
		}
	})
	return getDistinct(dates)
}

const getPartnerCodes = (emissions, modes) => {
	let codes = []
	let partnerCodes = []
	modes.forEach((mode) => {
		if (emissions[mode]?.length) {
			codes = emissions[mode].map((i) => i.partnerCode)
		}
		partnerCodes = [...partnerCodes, ...codes]
	})
	return getDistinct(partnerCodes)
}

const getPartnerSets = (PartnerCodes, Dates, Modes, emissions) => {
	const partnerDataSet = []
	for (const code of PartnerCodes) {
		partnerDataSet[code] = []
		for (const date of Dates) {
			let emissionTotal = 0
			for (const mode of Modes) {
				const modeDataSet = emissions[mode]
				for (let i = 0; i < modeDataSet.length; i++) {
					if (modeDataSet[i].date === date && modeDataSet[i].partnerCode === code) {
						emissionTotal += modeDataSet[i].emissions.total
					}
				}
			}
			partnerDataSet[code].push(emissionTotal)
		}
	}
	return partnerDataSet
}

export function getEmissionsDataSet(emissions = {}, modes = [], codes = []) {
	const data = []
	if (!emissions || Object.keys(emissions).length === 0) return undefined

	const partnerCodes = codes.length === 0 ? getPartnerCodes(emissions, modes) : codes
	const labels = getDataLabels(emissions, modes)
	const partnerDataSet = getPartnerSets(partnerCodes, labels, modes, emissions)

	for (const partnerCode of partnerCodes) {
		let result = getDataSet(partnerCode, partnerDataSet[partnerCode])
		data.push(result)
	}
	return { labels, data }
}

export function getRetirmentOffsetDataSet(retirements = [], isNegative = false, name = "Retirements") {
	if (!retirements || Object.keys(retirements).length === 0) return undefined
	const labels = retirements.map((i) => i.retirementMonth)
	const data = []

	let rows = retirements.map((i) => i.emissionValue)

	if (isNegative) rows = rows.map((r) => toNegativeNum(r))

	data.push(getDataSet(name, rows))
	return { labels, data }
}

export function getNetPositive(offset = [], emissions = [], name = "Net position") {
	if (!offset || !emissions) return []
	if (offset.length === 0 || emissions.length === 0) return undefined

	const data = []
	const rows = []

	for (let i = 0; i < offset.length; i++) {
		let netDiff = emissions[i] - offset[i]
		rows.push(netDiff === 0 ? null : netDiff)
	}

	data.push(getDataSet(name, rows))
	return { data }
}

export function getNetPosition(netPositions = []) {
	if (!netPositions || !netPositions.length) return undefined
	const data = []
	const labels = []
	let rows = netPositions.map((i) => i.netPosition)
	data.push(getDataSet("Net position", rows))
	return { labels, data }
}

export function getAllEmissionsByKeyForBar(emissionData, titleKey, emissionsKey = "emissions.total", mode = ALL_MODES) {
	if (!emissionData) return []
	if (mode.length === 0) return []

	let finalData = []

	for (const key of Object.keys(emissionData)) {
		if (mode.includes(key)) {
			for (const obj of emissionData[key]) {
				let dateIndex = finalData.findIndex((x) => x.xValue === obj[titleKey])

				// In final data, look for x value
				if (dateIndex < 0) {
					finalData.push({
						xValue: obj[titleKey],
						yValue: [],
					})
					dateIndex = finalData.length - 1
				}

				// In final data, look for mode
				let modeIndex = finalData[dateIndex].yValue.findIndex((y) => y.key === key)
				if (modeIndex < 0) {
					finalData[dateIndex].yValue.push({
						key,
						value: getValueFromNestedObject(obj, emissionsKey),
					})
				} else {
					finalData[dateIndex].yValue[modeIndex].value += getValueFromNestedObject(obj, emissionsKey)
				}
			}
		}
	}

	return finalData
}

export function getAllEmissionsByKeyForDonut(emissionData, emissionsKey = "emissions", mode = ALL_MODES) {
	if (!emissionData) return []
	if (mode.length === 0) return []

	let finalData = []

	for (const key of Object.keys(emissionData)) {
		if (mode.includes(key)) {
			for (const obj of emissionData[key]) {
				let keyIndex = finalData.findIndex((x) => x.key === key)

				// In final data, look for x value (date)
				if (keyIndex < 0) {
					finalData.push({
						key: key,
						value: getValueFromNestedObject(obj, emissionsKey),
					})
				} else {
					finalData[keyIndex].value += getValueFromNestedObject(obj, emissionsKey)
				}
			}
		}
	}

	return trimArrayOfKeyValueObjects(finalData)
}

export function getAllEmissionsByGroupForDonut(emissionData, innerKey = "groupName", emissionsKey = "emissions", mode = ALL_MODES) {
	if (!emissionData) return []
	if (mode.length === 0) return []

	let finalData = []

	for (const key of mode) {
		if (emissionData[key]) {
			for (const obj of emissionData[key]) {
				let keyIndex = finalData.findIndex((x) => x.key === obj[innerKey])

				// In final data, look for x value (date)
				if (keyIndex < 0) {
					finalData.push({
						key: obj[innerKey],
						value: getValueFromNestedObject(obj, emissionsKey),
					})
				} else {
					finalData[keyIndex].value += getValueFromNestedObject(obj, emissionsKey)
				}
			}
		}
	}

	return trimArrayOfKeyValueObjects(finalData)
}

export const buildHeirarchicalDataForCostCentres = (emissionData, mode = ALL_MODES) => {
	if (!emissionData) return []
	if (mode.length === 0) return []

	let hashKeyIncrement = 0
	let allData = []
	for (const key of mode) {
		allData = [...allData, ...emissionData[key]]
	}

	const result = getCostCentreByDepth(1, allData)

	function getCostCentreByDepth(depth, data, parent = null) {
		const currentDepthArrayIndex = depth - 1
		const ccsAtLevel = data.filter((x) => {
			// Ensure parent obj is equal to parent of current depth's CC
			if (x.costCentres.length === depth) {
				if (!parent) return true

				const lastCostCentreInCurrentObj = x.costCentres[currentDepthArrayIndex - 1]
				const { name, code } = lastCostCentreInCurrentObj
				const { name: rowParentCCName, code: rowParentCCCode } = parent

				return x.costCentres.length === depth && name === rowParentCCName && code === rowParentCCCode
			}

			return false
		})

		// Recurse thru CC data
		let rowObj = {}
		for (const row of ccsAtLevel) {
			hashKeyIncrement++
			const ccHash = JSON.stringify(row.costCentres)

			if (!rowObj[ccHash]) {
				const { name, code } = row.costCentres[currentDepthArrayIndex]
				rowObj[ccHash] = {
					hashKey: `${hashKeyIncrement}`,
					costCentre: { name, code },
					value: { name: name ?? code, total: 0 },
					childrenValues: getCostCentreByDepth(depth + 1, data, row.costCentres[currentDepthArrayIndex]),
				}
			}

			if (!rowObj[ccHash].value[row.date]) {
				rowObj[ccHash].value[row.date] = row.emissions.total
			} else {
				rowObj[ccHash].value[row.date] += row.emissions.total
			}
			rowObj[ccHash].value["total"] += row.emissions.total
		}

		return Object.keys(rowObj).map((key) => rowObj[key])
	}

	return result
}

export function getValueFromNestedObject(obj, path) {
	if (!obj) return undefined

	const keys = path.split(".")
	let value = obj

	for (let key of keys) {
		if (Object.prototype.hasOwnProperty.call(value, key)) {
			value = value[key]
		} else {
			return undefined // Return undefined if any key is not found
		}
	}

	return value
}
