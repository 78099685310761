import StackedBarLegend from "./StackedBarLegend"
import GraphTitle from "../../../shared/GraphTitle"
import Box from "@mui/material/Box"

export default function StackedBarHeading({ title, subTitle, colorScale, filters, filterData, width }) {
	return (
		<Box className="overflow-auto position-absolute" {...{ width }}>
			<Box className="float-left">
				<GraphTitle title={title} subTitle={subTitle} />
			</Box>
			<Box className={width > 500 ? "float-right" : "float-left p-t-6"}>
				<Box className={`display-flex justify-center font-size-${width > 500 ? "14" : "8"}`}>
					<StackedBarLegend scale={colorScale} filters={filters} filterData={filterData} parentWidth={width} />
				</Box>
			</Box>
		</Box>
	)
}
