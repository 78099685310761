import { Typography } from '@mui/material'
import React from 'react'
import EnvBanner from '../../assets/banners/article1.png'
import { usePageTitle } from '../../hooks/TitleHook'
import ArticleLayout from '../../layout/ArticleLayout'

const Article1 = () => {
    usePageTitle('BlueHalo - Take Action')
    return (
        <ArticleLayout banner={EnvBanner} 
        title="Taking action on climate means protecting the future of humanity"
        subtitle="BlueHalo and Tasman Environmental Markets know what's at stake and hold a deep commitment to achieving aggressive goals through excellence in climate action projects to achieve net zero.">
            <Typography variant='body1'>
                The science is clear: the future of our planet is at risk due to the changing global climate. At BlueHalo and Tasman Environmental Markets,
                we believe that collectively - as individuals and businesses across the globe - we can take action today to protect future generations and mankind.
                <br /><br />
                Founded in 2014, our business revolves around this vision. We develop solutions to facilitate action,
                focusing on the supply of advanced technologies and services in the carbon market.
                <br /><br />
                Alongside those who have worked for decades in tackling climate change, we were not surprised by the results published in the
                <a href='https://www.ipcc.ch/assessment-report/ar6/' className="ipcc-link" target="_blank" rel="noopener noreferrer"> August 2021 report from the UN Intergovernmental Panel on Climate Change. </a>
                The rise in atmospheric greenhouse gas concentrations is higher than ever; average global temperatures are 1.2 degrees warmer than pre-industrial temperatures, and rising; our oceans continue to grow more acidic; and our ecosystems are continually under threat.
                <br /><br />
                Greenhouse gas emissions from human activities are the cause. We are already seeing the effects of rising temperatures, with extreme weather events a stark reality for many industries. Furthermore, the second order effects of these extreme weather patterns are not just causing problems for towns and cities.
                They are also impacting operations for some business sectors, such as transport, tourism, manufacturing and energy.
                <br /><br />
                For the travel and airline industry, these effects are widely disruptive already. Increased temperatures and rising sea levels change where and when it's safe to travel.
                Additionally, industry-wide operational interruptions are commonplace, as many carriers experience weather-related interference issues, typically due to unseasonably high temperatures. Extreme heat often shuts down runways or causes the need for reduced freight loads.
                <br /><br />
                From all this, it's clear to see how carbon issues dominate the climate change discussions within the travel and airlines sectors.
                As it stands, the airline industry is responsible for reducing emissions of approximately 21.2 gigatons of CO2 between today and 2050. At BlueHalo, we recognise this requires a substantial commitment and strong collaborations alongside technology and innovative advancements. We will continue to support the industry to achieve these goals.
                <br /><br />
                Our mission is to facilitate emissions reduction through climate action programs, which will remain a fundamental part of any airline's net zero strategy for some time.
                We commit to providing the most advanced carbon offset solutions and access to credible climate action projects for organisations to invest in, to achieve credible offset results.
                This allows airlines to make a substantial difference through projects such as renewable energy generation, rainforest protection, and wildfire prevention programs.
                <br /><br />
                Our technology solutions and climate action services enable businesses and individuals to offset emissions today,
                while simultaneously reducing emissions and investing in the solutions to remove emissions from their future operations.
                <br /><br />
                Voluntary demand for carbon offsets is increasing as individuals and organisations factor the risks of climate change into their future planning.
                It's more than a measurable and credible way to neutralise greenhouse emissions; investing in carefully chosen carbon offset projects
                will deliver social and economic benefits to communities beyond emissions reductions.
            </Typography>
        </ArticleLayout>

    )
}

export default Article1