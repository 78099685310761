import { Link as RouterLink } from "react-router-dom"

const LinkType = ({ external = false, children, link }) => {
	return external ? (
		<a data-testid={"custom-menu-item-link"} className="txt-decoration-none anchor" href={link} target="_blank" rel="noreferrer">
			{children}
		</a>
	) : (
		<RouterLink data-testid={"custom-menu-item-link"} className="txt-decoration-none router" to={link}>
			{children}
		</RouterLink>
	)
}
export default LinkType
