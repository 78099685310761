import { createSvgIcon } from "@mui/material/utils"
import * as React from "react"

export const IcCalendar = createSvgIcon(
	<path
		fillRule="evenodd"
		clipRule="evenodd"
		d="M3.5 0.5C3.26794 0.5 3.04538 0.592187 2.88128 0.756282C2.71719 0.920376 2.625 1.14294 2.625 1.375V2.25H1.75C1.28587 2.25 0.840752 2.43437 0.512563 2.76256C0.184374 3.09075 0 3.53587 0 4V12.75C0 13.2141 0.184374 13.6592 0.512563 13.9874C0.840752 14.3156 1.28587 14.5 1.75 14.5H12.25C12.7141 14.5 13.1592 14.3156 13.4874 13.9874C13.8156 13.6592 14 13.2141 14 12.75V4C14 3.53587 13.8156 3.09075 13.4874 2.76256C13.1592 2.43437 12.7141 2.25 12.25 2.25H11.375V1.375C11.375 1.14294 11.2828 0.920376 11.1187 0.756282C10.9546 0.592187 10.7321 0.5 10.5 0.5C10.2679 0.5 10.0454 0.592187 9.88128 0.756282C9.71719 0.920376 9.625 1.14294 9.625 1.375V2.25H4.375V1.375C4.375 1.14294 4.28281 0.920376 4.11872 0.756282C3.95462 0.592187 3.73206 0.5 3.5 0.5ZM3.5 4.875C3.26794 4.875 3.04538 4.96719 2.88128 5.13128C2.71719 5.29538 2.625 5.51794 2.625 5.75C2.625 5.98206 2.71719 6.20462 2.88128 6.36872C3.04538 6.53281 3.26794 6.625 3.5 6.625H10.5C10.7321 6.625 10.9546 6.53281 11.1187 6.36872C11.2828 6.20462 11.375 5.98206 11.375 5.75C11.375 5.51794 11.2828 5.29538 11.1187 5.13128C10.9546 4.96719 10.7321 4.875 10.5 4.875H3.5Z"
		fill="#9DA3AF"
	/>,
	"IcCalendar"
)
