import { Typography } from '@mui/material'
import React from 'react'
import EnvBanner from '../../assets/banners/article2.png'
import { usePageTitle } from '../../hooks/TitleHook'
import ArticleLayout from '../../layout/ArticleLayout'

const Article2 = () => {
    usePageTitle('BlueHalo - Price Of Carbon')
    return (
        <ArticleLayout
            banner={EnvBanner}
            title="Why are some carbon offsets more expensive than others? There are very real reasons why - and knowing what's happening is at the heart of having a quality climate action portfolio."
            subtitle="Why are some carbon offsets more expensive than others? There are very real reasons why - and knowing what's happening is at the heart of having a quality climate action portfolio."
        >
            <Typography variant='body1'>
                It makes sense that many believe that a carbon offset is a carbon offset. So why are some cheap and others more expensive?
                There are a few factors that impact offset prices - and they can mean a lot to the credibility of your climate action outcomes.
                One of the most critical factors is the level of project due diligence taking place.
                <br /><br />

                First things first: a carbon offset represents one tonne of carbon emissions removed or prevented from entering our atmosphere.
                So why can you buy a carbon offset for two dollars, and then be offered another carbon offset at ten, twenty, or forty times the price?
                <br /><br />
                Think of it like taking a trip to your local nursery to buy 100kg of soil for your garden bed.
                Without looking at the details, you might decide to simply buy the cheapest soil available. But will it really help your garden grow?
                Any gardening expert will tell you, within that soil lie many different factors that contribute to the quality of the product - and the price.
                <br /><br />
                For carbon offsets, there are also aspects of a project that can play a role in the quoted price.
                And price in itself is not a direct measure of the quality of a project.
                <br /><br />
                Consider an offset generated from a wind farm in India where the wind turbines spin close to 24/7,
                generating carbon offsets from the coal generation it displaces. The marginal cost of each offset in this case is small.
                Compare this to a small-scale tree planting exercise along a coastline in Australia. Here, you have costs of trees, pest control, labour, and more. The cost per offset in this case is likely to be higher. Much higher.
                <br /><br />
                Both projects create an offset and have the ability to deliver genuine social and environmental 'co-benefits'.
                But all those factors lead to significant differences in the cost of funding the project and making it available as an offset.
                <br /><br />
                Sometimes, it's other factors that help determine the price tag. Governments in different countries can be involved with setting the price,
                including maximum or minimum prices. Project setup, ongoing costs, how remotely it is located, and its size are usually big factors.
                <br /><br />
                Another key is which certification standard the project uses to independently verify its carbon offset.
                Industry standards ensure projects stack up to the rigorous requirements in place for these commodities.
                Different standards come with different checks and balances and can therefore result in a larger audit cost.
                <br /><br />
                All of this points to an important lesson for any organisation looking to navigate offset prices.
                It's important to work with a team that knows each project it sells, has done proper due diligence,
                and holds the appropriate licensing for the markets in which it is operating. For example, in Australia,
                licensing is provided by ASIC under the Australian Financial Services Licensing Regime.
                <br /><br />
                Unpicking the details of projects is something BlueHalo spends a lot of time on. Backed by Tasman Environmental Markets,
                we have a deep understanding of how to value offsets and build climate action portfolios for the clients we work with.
                <br /><br />
                A lower cost price on a similar-sounding project can ring alarm bells.
                It might indicate corners are being cut on due diligence and risk management.
                In fact, this process is completely overlooked in.
                <br /><br />
                some instances, putting your offset purchase and climate change strategy at unwanted risk - potentially damaging your brand reputation.
                <br /><br />
                Not all carbon offsets are priced the same, and that's OK. There's much more to consider than just the price of the project.
                Seek guidance from those with real experience, who hold the correct licensing, and do their due diligence.
                Working with organisations like BlueHalo will achieve real climate action results and deliver high quality benefits to your organisation and its brand.
            </Typography>
        </ArticleLayout>
    )
}

export default Article2