/* VEHICLE_EMISSIONS_ACRISS_ENDPOINT */
import { VEHICLE_EMISSIONS_ACRISS_ENDPOINT } from "../../util/Constants"
import { useQueryEndpoint } from "../../hooks/QueryHooks"
import { setQueryParameters } from "../sub-components/SetQueryParameters"
import { DisplaySingleModeTable } from "./displayTable"

export const GetVehicleEmissionsAcriss = () => {
	const body = setQueryParameters("POST")
	const { data, error, loaded } = useQueryEndpoint(VEHICLE_EMISSIONS_ACRISS_ENDPOINT, body)
	if (loaded && !error) {
		const emissionsData = data.data.roadEmissions ?? []

		return <DisplaySingleModeTable data={emissionsData} title={"Vehicle Emissions Acriss Endpoint"} caption={`${VEHICLE_EMISSIONS_ACRISS_ENDPOINT}`} totaller="emissions" />
	}
	return <>Loading...</>
}
