import { useModeList } from "../../hooks/ModeHook"
import { useQueryEndpoint } from "../../hooks/QueryHooks"
import { EMISSIONS_OVERVIEW_ENDPOINT, LATEST_API_VERSION } from "../../util/Constants"
import { setQueryParameters } from "../sub-components/SetQueryParameters"
import Table from "@mui/material/Table"
import TableBody from "@mui/material/TableBody"
import TableCell from "@mui/material/TableCell"
import TableHead from "@mui/material/TableHead"
import TableRow from "@mui/material/TableRow"
import TableFooter from "@mui/material/TableFooter"

export const EmissionsOverviewEndpoint = () => {
	const queryString = setQueryParameters()
	let modes = useModeList()
	const { data, error, loaded } = useQueryEndpoint(EMISSIONS_OVERVIEW_ENDPOINT, false, "get", { "api-version": LATEST_API_VERSION, interval: "month", ...queryString })

	if (loaded && !error) {
		const overviews = data?.data
		let overviewList = []
		let totalEmissions = 0
		modes.forEach((mode) => {
			if (overviews[mode] !== undefined) {
				const modeNet = overviews[mode]
				let modeTotal = 0
				const modeNetList = modeNet?.map((overview, i) => {
					modeTotal += overview.emissions.total
					return (
						<TableRow key={i}>
							<TableCell>{overview.date}</TableCell>
							<TableCell>{overview.emissions.total}</TableCell>
							<TableCell>{overview.partnerCode}</TableCell>
						</TableRow>
					)
				})
				totalEmissions += modeTotal
				overviewList.push(
					<Table key={`emissions${mode}`}>
						<TableHead>
							<TableRow>
								<TableCell colSpan="3">{mode}</TableCell>
							</TableRow>
							<TableRow>
								<TableCell>Date</TableCell>
								<TableCell>Emissions</TableCell>
								<TableCell>Partner</TableCell>
							</TableRow>
						</TableHead>
						<TableBody>{modeNetList}</TableBody>
						<TableFooter>
							<TableRow>
								<TableCell>Total:</TableCell>
								<TableCell>{modeTotal}</TableCell>
							</TableRow>
						</TableFooter>
					</Table>
				)
			}
		})

		return (
			<>
				<h3>Emissions Overview</h3>
				<h4>{EMISSIONS_OVERVIEW_ENDPOINT}</h4>
				<Table>
					<TableBody>
						<TableRow>
							{overviewList.map((overview, i) => (
								<TableCell key={i} style={{ verticalAlign: "top" }}>
									{overview}
								</TableCell>
							))}
						</TableRow>
					</TableBody>
					<TableFooter>
						<TableRow>
							<TableCell>Emissions Total:</TableCell>
							<TableCell>{totalEmissions}</TableCell>
						</TableRow>
					</TableFooter>
				</Table>
			</>
		)
	}
	return <>Loading...</>
}
