import { Box, ClickAwayListener, InputAdornment, MenuItem } from "@mui/material"
import React, { useState } from "react"
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown"
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp"
import SearchIcon from "@mui/icons-material/Search"
import MenuItemSearch from "./sub-components/MenuItemSearch"
import styles from "../index.module.scss"
import FilterWrapperButton from "./sub-components/FilterWrapperButton"
import FilterWrapperButtonChip from "./sub-components/FilterWrapperButtonChip"
import FilterWrapperMenu from "./sub-components/FilterWrapperMenu"
import FilterWrapperContainer from "./sub-components/FilterWrapperContainer"

export default function SelectFilterWrapper({ type = "multi", label, leftIcon, children, onChange, className, hideSearch = false }) {
	const [selected, setSelected] = useState({})
	const [anchorEl, setAnchorEl] = useState(null)
	const [searchText, setSearchText] = useState("")

	const open = Boolean(anchorEl)
	const selectedItems = Object.keys(selected).filter((x) => selected[x])

	const handleClick = (event) => {
		if (!open) {
			setAnchorEl(event.currentTarget)
		} else {
			handleClose()
		}
	}
	const handleClose = () => {
		setAnchorEl(null)
	}

	const updateSelected = (key) => {
		if (type == "multi") {
			let newSelected = selected
			if (!newSelected[key]) {
				newSelected[key] = true
			} else {
				newSelected[key] = !selected[key]
			}
			setSelected({ ...newSelected })
		} else {
			setSelected({ [key]: true })
		}
		onChange(selected)

		if (type !== "multi") handleClose()
	}

	return (
		<FilterWrapperContainer className={className} open={open}>
			<FilterWrapperButton color="primary" component="label" onClick={handleClick} open={open}>
				{selectedItems.length === 0 ? (
					<Box className="flex-row align-center" data-testid={"button-label"}>
						{leftIcon && leftIcon}
						<Box className="m-r-1-theme float-left">{label}</Box>
					</Box>
				) : (
					<Box className="position-relative h-100-percent">
						{type === "multi" ? (
							<Box className="position-relative h-100-percent">
								{open && selectedItems.length <= 2 ? (
									<Box className="position-relative h-100-percent">
										{selectedItems.map((item, i) => (
											<FilterWrapperButtonChip text={item} deleteItem={() => updateSelected(item)} />
										))}
									</Box>
								) : (
									<Box className="position-relative h-100-percent">
										{selectedItems.slice(0, 2).map((item) => (
											<FilterWrapperButtonChip text={item} deleteItem={() => updateSelected(item)} />
										))}{" "}
										{selectedItems.length - 2 > 0 ? `+ ${selectedItems.length - 2}` : null}
									</Box>
								)}
							</Box>
						) : (
							<Box data-testid="radio-filter-label">{selectedItems[0]}</Box>
						)}
					</Box>
				)}
				{open ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
			</FilterWrapperButton>

			{open && (
				<ClickAwayListener onClickAway={handleClose}>
					<FilterWrapperMenu disableAutoFocusItem disableAutoFocus onKeyDown={(e) => e.stopPropagation()}>
						{!hideSearch && (
							<Box data-testid="search-box" className={`p-lr-10 p-tb-1-theme ${styles["disable-menu-hover"]}`}>
								<MenuItem
									onClickCapture={(e) => {
										e.stopPropagation()
										e.preventDefault()
									}}
									onKeyDown={(e) => {
										e.stopPropagation()
									}}
									disableRipple
									disableGutters
									disableAutoFocus
									disableFocusRipple
									disableAutoFocusItem
									className={`p-tb-0 ${styles["disable-menu-hover"]}`}
								>
									<MenuItemSearch
										className="border-1 w-100-percent"
										placeholder="Search..."
										onChange={(e) => {
											setSearchText(e.target.value)
										}}
										endAdornment={
											<InputAdornment position="end">
												<SearchIcon />
											</InputAdornment>
										}
										disableUnderline
										disableRipple
										disableFocusRipple
									/>
								</MenuItem>
							</Box>
						)}
						{children({ updateSelected, selected, searchText })}
					</FilterWrapperMenu>
				</ClickAwayListener>
			)}
		</FilterWrapperContainer>
	)
}
