const { Input, styled } = require("@mui/material")

const MenuItemSearch = styled(Input)(({ theme }) => ({
	".MuiInputBase-input": {
		height: "38px",
		padding: theme.spacing(0, 0, 0, 1),
	},

	".MuiInputAdornment-root": {
		padding: theme.spacing(0, 1, 0, 0),
	},
}))

export default MenuItemSearch
