import { MenuItem } from "@mui/material"
import CheckboxItem from "../Controls/Checkbox/CheckboxItem"
import FilterWrapper from "../Wrapper/SelectFilterWrapper"
import FilterAltIcon from "@mui/icons-material/FilterAlt"
import { ACTIVITY_FILTERS } from "../../../util/Constants"

const ActivityFilterButton = (props) => {
	return (
		<FilterWrapper type="multi" label="Activity" leftIcon={<FilterAltIcon className="m-r-1-theme" style={{ color: "#9DA3AF", width: "20px" }} />} {...props}>
			{({ updateSelected, selected, searchText }) => {
				const containsText = (text, searchText) => text.toLowerCase().indexOf(searchText.toLowerCase()) > -1
				const filteredChildren = ACTIVITY_FILTERS.filter((x) => containsText(x.label, searchText))

				return (
					<>
						{filteredChildren.length ? (
							filteredChildren.map((child) => (
								<CheckboxItem id={child.id} key={child.id} checked={selected[child.label] ?? false} label={child.label} onClick={() => updateSelected(child.label)} />
							))
						) : (
							<MenuItem className="p-lr-10 txt-secondary" disableRipple disabled onKeyDown={(e) => e.stopPropagation()}>
								No results
							</MenuItem>
						)}
					</>
				)
			}}
		</FilterWrapper>
	)
}

export default ActivityFilterButton
