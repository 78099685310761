import * as React from "react"
import TableCell from "@mui/material/TableCell"
import { Box, TableRow } from "@mui/material"
import styles from "../../index.module.scss"
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight"
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown"

const formatString = (str) => {
	if (typeof str == "number") return str.toFixed(2)
	return str
}

export default function RowWithNestedRows({
	showIcon = false,
	indentRow = false,
	indent = 0,
	isChild,
	hashKey,
	expandHashKey,
	expandedHashKeys,
	value,
	childrenValues = [],
	hide = false,
	columns = null,
}) {
	const defaultPadding = 16
	const columnHeaders =
		columns ??
		Object.keys(value).map((title) => {
			return { title }
		})

	return (
		<>
			<TableRow
				onClick={() => expandHashKey(hashKey)}
				data-testid={`row-${hashKey}`}
				className={`${hide ? "display-none" : "display-table-row"} ${childrenValues.length ? "cursor-pointer" : ""}`}
			>
				{columnHeaders.map((column, index) => {
					const displayIcon = isChild && showIcon && index === 0 && childrenValues.length
					const indentPX = indentRow ? (indent + 1) * 32 - defaultPadding - (displayIcon ? 16 : 0) : 0

					return (
						<TableCell
							className={`font-semi-bold ${styles["expandable-table-cell"]} ${isChild && indent ? `p-l-${indentPX} p-tb-2-theme p-r-2-theme` : "p-2-theme"}`}
							key={`row-${hashKey}-cell-${column.title}`}
							data-testid={`row-${hashKey}-cell-${column.title}`}
							data-align={column.cellAlign ?? "left"}
							align={column.cellAlign}
						>
							{displayIcon ? (
								<Box className="float-left">
									{expandedHashKeys[hashKey] ? <KeyboardArrowDownIcon className={"font-size-13 m-t-2"} /> : <KeyboardArrowRightIcon className={"font-size-13 m-t-2"} />}
								</Box>
							) : null}
							<Box className={`txt-ellipsis ${displayIcon ? "m-l-2-theme" : null}`}>{formatString(value[column.title] ?? "-")}</Box>
						</TableCell>
					)
				})}
			</TableRow>
			{childrenValues.map((row) => {
				return (
					<RowWithNestedRows
						key={row.hashKey}
						hashKey={row.hashKey}
						expandedHashKeys={expandedHashKeys}
						expandHashKey={expandHashKey}
						hide={hide === true || expandedHashKeys[hashKey] !== true}
						value={row.value}
						childrenValues={row.childrenValues}
						columns={columns}
						isChild={true}
						indent={indent + 1}
						indentRow={indentRow}
						showIcon={showIcon}
					/>
				)
			})}
		</>
	)
}
