/* RETIREMENTS_CERTIFICATES_ENDPOINT */
import { useQueryEndpoint } from "../../hooks/QueryHooks"
import { RETIREMENTS_CERTIFICATES_ENDPOINT } from "../../util/Constants"
import { setQueryParameters } from "../sub-components/SetQueryParameters"

export const RetirementsCertificatesEndpoint = () => {
	const body = setQueryParameters("POST")
	const { data, error, loaded } = useQueryEndpoint(RETIREMENTS_CERTIFICATES_ENDPOINT, body)
	if (loaded && !error) {
		const cert = data?.data?.certificates || []
		return (
			<>
				<h3>Retirements Certificates Endpoint</h3>
				<h4>{RETIREMENTS_CERTIFICATES_ENDPOINT}</h4>
				<ul>
					{cert.map((cert, i) => (
						<li key={i}>
							<b>{cert.displayName}</b>: {cert.filename} - {cert.retirementDate}
						</li>
					))}
				</ul>
			</>
		)
	}
	return <>Loading...</>
}
