import { Skeleton, Stack } from "@mui/material"

const SkeletonSDGPanel = () => (
	<Stack spacing={0.5} sx={{ alignItems: "start" }}>
		<Skeleton animation="wave" variant="text" width="80%" />
		<Skeleton animation="wave" variant="text" width="60%" />
		<Skeleton animation="wave" variant="text" width="40%" />
		<Skeleton animation="wave" variant="text" width="60%" />
		<Stack spacing={0.5} sx={{ alignItems: "start" }} direction="row">
			<Skeleton animation="wave" variant="rectangular" width="62px" height="62px" />
			<Skeleton animation="wave" variant="rectangular" width="62px" height="62px" />
			<Skeleton animation="wave" variant="rectangular" width="62px" height="62px" />
			<Skeleton animation="wave" variant="rectangular" width="62px" height="62px" />
			<Skeleton animation="wave" variant="rectangular" width="62px" height="62px" />
			<Skeleton animation="wave" variant="rectangular" width="62px" height="62px" />
			<Skeleton animation="wave" variant="rectangular" width="62px" height="62px" />
			<Skeleton animation="wave" variant="rectangular" width="62px" height="62px" />
			<Skeleton animation="wave" variant="rectangular" width="62px" height="62px" />
			<Skeleton animation="wave" variant="rectangular" width="62px" height="62px" />
		</Stack>
	</Stack>
)

export default SkeletonSDGPanel
