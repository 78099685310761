import { atom } from "jotai"

const defaultMode = {
	air: {
		passenger: true,
		freight: true,
	},
	road: {
		passenger: true,
		freight: true,
	},
	sea: {
		passenger: true,
		freight: true,
	},
	rail: {
		passenger: true,
		freight: true,
	},
	accommodation: {
		passenger: true,
		freight: true,
	},
	isTravelPartner: true,
	isFreightPartner: true,
}

export const modeAtom = atom(defaultMode)

export const modeListAtom = atom(
	(get) => {
		const mode = get(modeAtom)
		if (mode.modes) {
			return Object.keys(mode.modes).filter((key) => mode.modes[key].passenger === true || mode.modes[key].freight === true)
		}
	},
	(set, newModes) => {
		set(modeAtom, newModes)
	}
)
