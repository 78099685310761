import { Button, Card, CardActions, CardContent, CardMedia, Stack, Typography, useTheme } from "@mui/material"
import React from "react"
import CssGrid from "../../layout/CssGrid"
import { palette } from "../../theme"
import Item from "../Item"
import Pie from "./Pie.svg"

const KeyImpacts = ({ items = [] }) => (
	<ul>
		{items.map((item, index) => (
			<li key={index}>{item}</li>
		))}
	</ul>
)

const PercentCompletedBadge = ({ amount }) => {
	const theme = useTheme()
	return (
		<Stack>
			<Item
				sx={{
					backgroundImage: `url(${Pie})`,
					boxShadow: "none",
					backgroundRepeat: "no-repeat",
					backgroundPosition: "center",
					backgroundSize: "fill",
					textAlign: "center",
				}}
			>
				<Typography variant="h3">{amount}%</Typography>
				<Typography variant="body1" sx={{ lineHeight: "12px", fontSize: "12px" }}>
					<b>
						Proportion <br /> of portfolio
					</b>
				</Typography>
			</Item>
		</Stack>
	)
}

const ProjectItem = ({ name, value }) => {
	const theme = useTheme()
	return (
		<Typography variant="body1" component="div" color={theme.palette.text.main}>
			{name}: <b>{value}</b>
		</Typography>
	)
}

const SDGProjectCard = ({ title, amount, type, img, body, keyImpacts, location, subtitle }) => {
	const theme = useTheme()

	return (
		<Card sx={{ bgcolor: theme.palette.white.main }}>
			<CardMedia component="img" height="200px" image={img} alt="alt image" />
			<CardContent sx={{ textAlign: "start", alignItems: "center" }}>
				<CssGrid container columnTemplate="auto auto">
					<CssGrid sx={{ mb: 1 }}>
						<Typography gutterBottom variant="h4" component="div" color={theme.palette.text.main}>
							{title}
						</Typography>
						<ProjectItem name="Project Type" value={type} />
						<ProjectItem name="Location" value={location} />
					</CssGrid>
				</CssGrid>
				<Typography variant="body1" color={theme.palette.text.main} gutterBottom>
					<b>{subtitle}</b>
				</Typography>
				<Typography variant="body1" color={theme.palette.text.main} gutterBottom>
					{body}
				</Typography>

				<Typography variant="body1" color={theme.palette.text.main}>
					<br />
					<b>Key Impacts</b>
					<br />
					<KeyImpacts items={keyImpacts} />
				</Typography>
			</CardContent>
			{/* <CardActions sx={{ justifyContent: 'end' }}>
        <Button size="small" variant='contained' sx={{ borderRadius: '2rem', padding: '0.5rem' }}>
          Download Marketing Collateral
        </Button>
      </CardActions> */}
		</Card>
	)
}

export default SDGProjectCard
