import React, { useEffect, useState } from "react"
import { useMultiQueryEndpoint, useQueryEndpoint } from "../../../hooks/QueryHooks"
import { useFilterAtomValue } from "../../../hooks/FilterHooks"
import { deepEqual } from "../../../util/util"

// Maintains state of data fetch, transformation of data, and filter state
const TableWrapper = ({ queryData, dataTransformFunction, frontendFilter = false, children }) => {
	const [lastData, setLastData] = useState([])
	const [filters, setFilters] = useState({})
	const [lastFilters, setLastFilters] = useState(null)
	const [transformedData, setTransformedData] = useState(null)

	const multiQueries = Array.isArray(queryData)
	const queryBody = queryData.method === "POST" ? queryData.body : null
	let queryParams = queryData.params ?? null

	if (frontendFilter === false) queryParams = { ...queryParams, ...filters }
	const { data, loaded, error } = !multiQueries ? useQueryEndpoint(queryData.url, queryBody, queryData.method, queryParams) : useMultiQueryEndpoint(queryData)

	const updateFilterValue = (newFilters) => {
		setFilters({ ...newFilters })
	}

	useEffect(() => {
		if (loaded && !error && !deepEqual(lastData, data)) {
			setTransformedData(dataTransformFunction(data, filters))
			setLastData(data)
		}
	})

	useEffect(() => {
		if (loaded && !error && !deepEqual(lastFilters, filters)) {
			setTransformedData(dataTransformFunction(data, filters))
		}
	}, [filters])

	return <>{children({ updateFilterValue, transformedData: transformedData ?? [], loaded, error, rawData: data ?? {} })}</>
}

export default TableWrapper
