import { Typography } from '@mui/material'
import React from 'react'
import EnvBanner from '../../assets/banners/article3.png'
import { usePageTitle } from '../../hooks/TitleHook'
import ArticleLayout from '../../layout/ArticleLayout'

const Article3 = () => {

    usePageTitle('BlueHalo - Brand Strategy')
    return (
        <ArticleLayout banner={EnvBanner}
            title="Why due diligence on carbon offsets is critical for your strategy and brand."
            subtitle="We're not all experts on carbon offsets. So it's important to choose a climate action partner that offers transparent insights and deep due diligence to support your journey to net zero.">

            <Typography variant='body1'>
                Carbon offsets are playing a critical role in corporate climate strategy. You can not easily go carbon neutral or reach net zero without them. However,
                as market demand grows and offsets face increasing amounts of scrutiny, the need for rigorous due diligence on offset supply projects becomes increasingly important.
                Working with a partner who understands high-quality climate action projects inside out will ensure your offsets deliver the measurable outcomes you need and deliver
                storytelling opportunities for your brand.
                <br /><br />

                BlueHalo is owned by Tasman Environmental Markets (TEM), an organisation with many years of experience establishing carbon offset projects.
                They hold deep insights into all facets of what goes into genuine climate action projects and how to deliver carefully calculated offsets.
                From rainforest protection initiatives in Southeast Asia to wind and solar farms in India, BlueHalo knows how to evaluate, process,
                monitor and measure the climate and community benefits of such projects.
                <br /><br />

                As new organisations enter the voluntary offset market, there's a temptation to put price above risk mitigation.
                This is a dangerous approach that can seriously impact any associated brand negatively.
                Not everyone has the same level of on-the-ground insight, and many 'experts' will see carbon offsets as a
                universal commodity to simply purchase off a trading platform and implement in a company's strategy.
                <br /><br />

                Examples of carbon suppliers not doing their due diligence on projects have regularly appeared in mainstream media, resulting in reputational and brand damage.
                These cases highlight the importance of understanding exactly what you're buying, and how that purchase aligns with your broader values.
                <br /><br />

                At BlueHalo, this is something we take very seriously. In providing high-quality offsets to our clients we know we are representing their brand and their values.
                We must protect theirs as if it were our own. To provide clients with confidence of project integrity and offset validity,
                BlueHalo undertakes a rigorous offset procurement process.
                <br /><br />

                <Typography variant='h5'>Step 1: Project identification</Typography>
                We research, travel, analyse and liaise with our trusted international contact base to identify projects that fit within the eligibility of relevant international standards.
                <br /><br />


                <Typography variant='h5'>Step 2: Project due diligence</Typography>
                TEM finely examines a range of project factors including the developer, owner, credit type, certification scheme, country and activity. Simultaneously,
                we complete a project risk model and a detailed due diligence checklist. Providing it meets our criteria to proceed,
                TEM collects full documentation from the project including the project description,
                validation representation, verification reports and ongoing monitoring reports.
                <br /><br />


                <Typography variant='h5'>Step 3: Project negotiation and risk management</Typography>
                The carbon offset market is complex, with multiple supply and demand variables influencing offset availability and prices.
                Following our due diligence process, we consider policy and legal, technology,
                <br /><br />

                market and economic factors, in addition to project-specific variables.
                This ensures we procure a sufficient supply of offsets for our clients at guaranteed competitive prices throughout our negotiations with project developers.
                <br /><br />

                <Typography variant='h5'>Step 4: Offset procurement</Typography>
                TEM's sophisticated proprietary trading platform, Miranda, combines all aspects of carbon procurement and offset retirement.
                Following negotiations, the team manages the contracting, purchasing, service delivery, stock control and retirements in real time, across all market channels and certification schemes.
                <br /><br />

                <Typography variant='h5'>Step 5: Offset retirement</Typography>
                After completing the transaction, TEM provides evidence via a Certificate of Retirement to our clients to verify the offset units have been retired in the relevant registry.
                <br /><br />

                Carbon offsets are integral to any company's net zero strategy.
                Going carbon neutral relies on procuring high quality offsets that achieve the metrics you need through projects that align closely with your brand goals.
                <br /><br />

                Understanding what you are buying means working with an organisation that offers transparent insights on the climate action process.
                By choosing BlueHalo, you can rest assured that TEM has the expertise and experience to validate quality climate action products that underpin your carbon neutral claims.
            </Typography>
        </ArticleLayout>
    )
}

export default Article3