import { Box, Container, Grid, Typography, useTheme } from "@mui/material"
import SDGChart from "../../components/Charts/Wireups/SDGChart"
import Item from "../../components/Item"
import { usePageTitle } from "../../hooks/TitleHook"

const SDG = () => {
	const theme = useTheme()
	usePageTitle("BlueHalo - UN SDG Coverage")
	return (
		<Box>
			<Grid container spacing={2}>
				<Grid item xs={12}>
					<Container sx={{ ml: -3 }}>
						<Typography className="txt-main" variant="h3" textAlign="start">
							UN Sustainable Development Goal Coverage
						</Typography>
						<Typography variant="body1" textAlign="start">
							Our due diligence analysts determine impact contribution to assess all projects alignment with the UN SDGs with varying degrees of certainty dependent on third-party
							verification of these impacts.
						</Typography>
					</Container>
				</Grid>
				<Grid item xs={12}>
					<Box maxWidth={1450} minHeight={300}>
						<Item sx={{ minHeight: 300 }}>
							<SDGChart />
						</Item>
					</Box>
				</Grid>
			</Grid>
		</Box>
	)
}

export default SDG
