import { useModeList } from "../../../hooks/ModeHook"
import { useQueryEndpoint } from "../../../hooks/QueryHooks"
import { EMISSIONS_OVERVIEW_ENDPOINT, RETIREMENT_OFFSET_ENDPOINT, NET_POSITION_ENDPOINT, LATEST_API_VERSION } from "../../../util/Constants"
import { CH6_Net_Position, getChartConfig } from "../../../lib/ChartsConfig"
import { getEmissionsDataSet, getNetPosition, getRetirmentOffsetDataSet } from "../../../lib/convertions/EmissionsDataSet"
import { processChart } from "../../../lib/HiChartHelper"
import { isResponsesLoaded } from "../../../util/util"
import SeriesChart from "../../SeriesChart/SeriesChart"
import ChartWrapper from "../ChartWrapper"
import { setQueryParameters } from "../../../pages/sub-components/SetQueryParameters"

const EmissionsWithNetPositive = () => {
	const body = setQueryParameters("POST")
	const queryString = setQueryParameters()

	const res1 = useQueryEndpoint(EMISSIONS_OVERVIEW_ENDPOINT, null, "GET", { "api-version": LATEST_API_VERSION, interval: "month", ...queryString })
	const res2 = useQueryEndpoint(RETIREMENT_OFFSET_ENDPOINT, body)
	const res3 = useQueryEndpoint(NET_POSITION_ENDPOINT, null, "GET", queryString)

	let modes = useModeList()
	let charOptions = getChartConfig("ch6")

	if (isResponsesLoaded([res1, res2, res3])) {
		const emissions = res1.data?.data
		const offsets = res2.data?.data?.retirements
		const netPositions = res3.data?.data?.netPositions
		if (emissions && offsets && netPositions) {
			const emissionsDS = getEmissionsDataSet(emissions, modes, body.partnerCodes)
			const offsetNegativeDS = getRetirmentOffsetDataSet(offsets, true)
			const netPosition = getNetPosition(netPositions, modes)

			if (offsetNegativeDS?.data) emissionsDS?.data.push(...offsetNegativeDS.data)
			if (netPosition?.data) emissionsDS?.data.push(...netPosition.data)
			// if there is no emissions data, use retirements data - future state there should always be emissions data if there is retirements data
			const chartLabels = emissionsDS !== undefined ? emissionsDS : offsetNegativeDS
			charOptions = processChart(chartLabels, CH6_Net_Position)
		}
	}

	return (
		<ChartWrapper loaded={isResponsesLoaded([res1, res2, res3])}>
			<SeriesChart isStockChart={false} options={charOptions} />
		</ChartWrapper>
	)
}
export default EmissionsWithNetPositive
