/* donutChartEndpoints */
import { useQueryEndpoint } from "../../hooks/QueryHooks"
import { setQueryParameters } from "../../pages/sub-components/SetQueryParameters"

import Table from "@mui/material/Table"
import TableBody from "@mui/material/TableBody"
import TableCell from "@mui/material/TableCell"
import TableHead from "@mui/material/TableHead"
import TableRow from "@mui/material/TableRow"
import TableFooter from "@mui/material/TableFooter"

export const DonutChartEndpoints = ({ queryData, modeOverride, title }) => {
	let queryParams = {}
	let queryBody = {}
	if (queryData.method == "get") {
		const getQueryParameters = setQueryParameters()
		queryParams = { ...getQueryParameters, ...queryData.params }
	} else {
		queryBody = { ...setQueryParameters("POST"), ...queryData.body }
	}
	const { data, loaded, error } = useQueryEndpoint(queryData.url, queryBody, queryData.method, queryParams ? queryParams : null)

	if (loaded && !error) {
		const chart = data?.data
		let chartList = []
		let chartTotal = 0
		modeOverride.forEach((mode) => {
			if (chart[mode] !== undefined) {
				const modeArray = chart[mode]
				let modeTotal = 0
				const modeArrayList = modeArray?.map((item, i) => {
					modeTotal += item.emissions
					return (
						<TableRow key={i}>
							<TableCell>{item.groupName}</TableCell>
							<TableCell>{item.emissions}</TableCell>
						</TableRow>
					)
				})
				chartTotal += modeTotal
				chartList.push(
					<Table key={`groupBy${mode}`}>
						<TableHead>
							<TableRow>
								<TableCell colSpan="2">
									{mode} - {title}
								</TableCell>
							</TableRow>
							<TableRow>
								<TableCell>Group Name</TableCell>
								<TableCell>Emissions</TableCell>
							</TableRow>
						</TableHead>
						<TableBody>{modeArrayList}</TableBody>
						<TableFooter>
							<TableRow>
								<TableCell>Total:</TableCell>
								<TableCell>{modeTotal}</TableCell>
							</TableRow>
						</TableFooter>
					</Table>
				)
			}
		})

		return (
			<>
				<h4>{queryData.url}</h4>
				<Table>
					<TableBody>
						<TableRow>
							{chartList.map((chart, i) => (
								<TableCell key={i} style={{ verticalAlign: "top" }}>
									{chart}
								</TableCell>
							))}
						</TableRow>
					</TableBody>
				</Table>
			</>
		)
	}
	return <>Loading...</>
}
