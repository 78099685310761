import { Checkbox, FormControlLabel, MenuItem, Typography } from "@mui/material"

const CheckboxIcon = () => {
	return (
		<svg data-testid={"unchecked-svg"} width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
			<rect x="1" y="1" width="10" height="10" rx="2" stroke="#D9D9D9" strokeWidth="2" />
		</svg>
	)
}

const CheckedIcon = () => {
	return (
		<svg data-testid={"checked-svg"} width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
			<rect x="1" y="1" width="10" height="10" rx="2" stroke="#D9D9D9" strokeWidth="2" />
			<path d="M2.14941 6L5.54231 9.20539" stroke="#5EB0EF" strokeWidth="2" />
			<path d="M4.29297 9.29297L10.5 3.08594" stroke="#5EB0EF" strokeWidth="2" />
		</svg>
	)
}

export default function CheckboxItem({ id, checked, label, onClick }) {
	return (
		<MenuItem
			disableRipple
			onClick={(e) => {
				onClick()
				e.preventDefault()
			}}
			className="p-lr-2-theme"
			data-testid={"menu-item"}
		>
			<FormControlLabel
				key={id}
				control={<Checkbox data-testid={"checkbox-item-control"} icon={<CheckboxIcon />} checkedIcon={<CheckedIcon />} checked={checked} disableRipple />}
				label={
					<Typography data-testid={"checkbox-item-label"} className="font-semi-bold">
						{label}
					</Typography>
				}
			/>
		</MenuItem>
	)
}
