import { Button, Typography } from "@mui/material"
import { Box } from "@mui/system"
import _ from "lodash"
import React from "react"
import { Link } from "react-router-dom"
import { useQueryEndpoint } from "../../hooks/QueryHooks"
import { SDG_ENDPOINT } from "../../util/Constants"
import LoadingWrapper from "../Loading/LoadingWrapper"
import SkeletonSDGPanel from "./SkeletonSDGPanel"
import { setQueryParameters } from "../../pages/sub-components/SetQueryParameters"
import styles from "./index.module.scss"

export const ImageItem = (img, active = true, i) => <img data-testid="image-item" key={i} src={img} height="auto" className={`${styles["sdg-panel-image"]} ${active ? "" : styles["inactive"]}`} />

export const SDGPanelBody = ({ data }) => {
	const marginBottom = "1rem"

	return (
		<Box style={{ overflow: "auto" }}>
			<Typography variant="h4" style={{ marginBottom }}>
				Your top UN Sustainable Development Goals
			</Typography>
			<Typography variant="body1" style={{ marginBottom: `calc(${marginBottom} - 10px)` }}>
				Here is a list of the goals that you are contributing to the most as part of your offset portfolio
			</Typography>
			<Box style={{ overflow: "auto", marginBottom, marginTop: "40px" }}>{data.map((v, i) => ImageItem(v.sdgUrl, v.projects.length > 0 ? true : false, i))}</Box>
			{/*<Box style={{ height: "20px" }}>
				<Box style={{ position: "absolute", bottom: "15px", right: "15px", height: "overflow" }}>
					<Link to={"/reports/sdg"} className="txt-decoration-none">
						<Button size="small" className="txt-blue txt-transform-none">
							See the full breakdown
						</Button>
					</Link>
				</Box>
			</Box>*/}
		</Box>
	)
}

export const AlternativeSDGPanel = () => {
	const marginBottom = "1rem"

	return (
		<Box data-testid={"alt-sdg"} style={{ overflow: "auto" }}>
			<Typography variant="h4" style={{ marginBottom }}>
				You Aren't Yet Contributing To Any Of The U.N's Sustainable Goals
			</Typography>
			<Typography variant="body1" style={{ marginBottom }}>
				Contributing to these goals is something to tell your customers and prospective new customers about. Explore your coverage in more detail, then grab some related marketing collateral
				and broadcast your companies involvement in these goals. If you need different metrics for your internal sustainability benchmark reporting, please let us know.
			</Typography>
		</Box>
	)
}

const SDGPanel = () => {
	const queryString = setQueryParameters()
	const { data, error, loaded } = useQueryEndpoint(SDG_ENDPOINT, false, "get", queryString)

	if (loaded && !error) {
		const offsets = data?.data?.sdgs || []
		const dataList = _.orderBy(offsets, "projects.length", "desc")
			.filter((i) => i.projects.length > 0)
			.slice(0, 10)

		return dataList.length === 0 ? <AlternativeSDGPanel /> : <SDGPanelBody data={dataList} />
	}

	return <LoadingWrapper loaded={loaded} error={error} loadingComponent={<SkeletonSDGPanel />} />
}
export default SDGPanel
