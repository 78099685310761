import React, { useEffect, useState } from "react"
import LoadingWrapper from "../../Loading/LoadingWrapper"
import { useMultiQueryEndpoint, useQueryEndpoint } from "../../../hooks/QueryHooks"
import LoadingSpinner from "../../Loading/LoadingSpinner"
import { ParentSize } from "@visx/responsive"
import { deepEqual } from "../../../util/util"
import { setQueryParameters } from "../../../pages/sub-components/SetQueryParameters"

const VisxChartWrapper = ({ queryData, dataTransformFunction, spinnerMargin = 0, children }) => {
	const [lastData, setLastData] = useState([])
	const [transformedData, setTransformedData] = useState(null)

	let queryParams = {}
	if (queryData.method == "get") {
		const getQueryParameters = setQueryParameters()
		queryParams = { ...getQueryParameters, ...queryData.params }
	} else {
		queryData.body = setQueryParameters("POST")
	}

	const multiQueries = Array.isArray(queryData)
	const { data, loaded, error } = !multiQueries ? useQueryEndpoint(queryData.url, queryData.body, queryData.method, queryParams ? queryParams : null) : useMultiQueryEndpoint(queryData)

	useEffect(() => {
		if (loaded && !error && !deepEqual(lastData, data)) {
			setTransformedData(dataTransformFunction(data))
			setLastData(data)
		}
	})

	return (
		<ParentSize>
			{(parent) => (
				<LoadingWrapper loaded={loaded} error={error} loadingComponent={<LoadingSpinner margin={spinnerMargin} />}>
					{children({ transformedData: transformedData ?? [], width: parent.width, height: parent.height })}
				</LoadingWrapper>
			)}
		</ParentSize>
	)
}

export default VisxChartWrapper
