import { Chip, styled } from "@mui/material"

const ModeChip = styled(Chip, { shouldForwardProp: (prop) => prop !== "background" })(({ theme, background }) => ({
	textTransform: "capitalize",
	borderRadius: "4px",
	background: background ?? theme.palette.skyBlue.secondary,
	fontSize: 12,
	color: theme.palette.white.main,
	padding: theme.spacing(0.5, 0.75),
	textAlign: "center",
	height: "23px",
}))

export default ModeChip
