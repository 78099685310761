// Surface errors to the UI here.  This would be a good place to send said errors to server side logging when implemented
export const ErrorHandling = (error, logging) => {
    // Might need to map error codes to human friendly text here first
    if (Array.isArray(logging)) {
        logging.forEach(e => console.log(e));
    } else if (logging) {
        console.log(logging)
    }
    return (
        error ? alert(error) : null // we want to do something a bit nicer here
    )
}