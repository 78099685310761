import { Card, CardContent, Skeleton, Typography, useTheme } from "@mui/material"
import React from "react"
import CssGrid from "../../layout/CssGrid"

const SkeletonProject = () => {
	const theme = useTheme()
	return (
		<Card sx={{ maxWidth: 400, bgcolor: theme.palette.white.main, display: "flex", flexDirection: "column", flexGrow: 1 }}>
			<Skeleton data-testid={"skeleton"} animation="wave" variant="rectangular" height="200px" width="400px" />
			<CardContent sx={{ textAlign: "start", flexGrow: 1, alignItems: "center" }}>
				<CssGrid container columnTemplate="auto auto">
					<CssGrid>
						<Typography gutterBottom variant="h4" component="div" color={theme.palette.skyBlue.mid}>
							<Skeleton animation="wave" variant="text" width="100%" />
						</Typography>
						<Skeleton animation="wave" variant="text" width="70%" />
						<Skeleton animation="wave" variant="text" width="70%" />
						<Skeleton animation="wave" variant="text" width="70%" />
					</CssGrid>
					<CssGrid sx={{ alignItems: "center" }}>
						<Skeleton animation="wave" variant="circular" width="72px" height="72px" />
					</CssGrid>
				</CssGrid>
				<Skeleton animation="wave" variant="text" width="90%" />
				<Skeleton animation="wave" variant="text" width="60%" />
				<Skeleton animation="wave" variant="text" width="90%" />
				<Skeleton animation="wave" variant="text" width="90%" />
				<Skeleton animation="wave" variant="text" width="60%" />
				<Skeleton animation="wave" variant="text" width="90%" />
				<Skeleton animation="wave" variant="text" width="90%" />
				<Skeleton animation="wave" variant="text" width="90%" />
				<Skeleton animation="wave" variant="text" width="90%" />
				<Skeleton animation="wave" variant="text" width="60%" />
				<Skeleton animation="wave" variant="text" width="90%" />
				<Skeleton animation="wave" variant="text" width="60%" />
				<Skeleton animation="wave" variant="text" width="90%" />
				<Skeleton animation="wave" variant="text" width="90%" />
			</CardContent>
		</Card>
	)
}

export default SkeletonProject
