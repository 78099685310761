export const getLocalStorageValueByKey = (key) => {
	let storedItem = localStorage.getItem(key)
	return storedItem ? JSON.parse(storedItem) : null
}

export const setLocalStorageValueByKey = (key, value) => {
	return localStorage.setItem(key, JSON.stringify(value))
}

export const localStorageKeys = {
	HEADER_DATE: "HEADER_DATE",
	PARTNER_CODE: "PARTNER_CODE",
}
