import { useState } from "react"
import { Box } from "@mui/material"
import CustomFilterWrapper from "../Wrapper/CustomFilterWrapper"
import { IcCalendar } from "../../../icon/IcCalendar"
import DateRangePicker from "../../DateRangePicker/DatePicker"
import moment from "moment"

const dateFormat = "MMM D, YYYY"

const DatePickerFilterButton = (props) => {
	const { DatePickerProps, label, className, onChange, value } = props
	const fromKey = DatePickerProps.fromKey ?? "dateFrom"
	const toKey = DatePickerProps.toKey ?? "dateTo"
	const [filterData, setFilterData] = useState({ [fromKey]: value[fromKey], [toKey]: value[toKey] })
	const [isDefault, setIsDefault] = useState(true)

	const updateDate = (data) => {
		const newVal = { [toKey]: data[toKey], [fromKey]: data[fromKey] }

		onChange(newVal)
		setFilterData(newVal)
		if (isDefault) setIsDefault(false)
	}

	const buildLabel = () => {
		const fromString = moment(filterData[fromKey]).format(dateFormat)
		const toString = moment(filterData[toKey]).format(dateFormat)
		return `${fromString} - ${toString}`
	}

	const resetValues = () => {
		updateDate({ [toKey]: null, [fromKey]: null })
		setIsDefault(true)
	}

	const labelValue = isDefault ? label : buildLabel()
	const filterDataValue = isDefault ? {} : filterData

	return (
		<CustomFilterWrapper
			valueSelected={filterDataValue[fromKey] || filterDataValue[toKey]}
			clearFunction={() => resetValues()}
			label={labelValue}
			className={className}
			leftIcon={<IcCalendar viewBox="0 0 14 15" className="m-r-10 m-l-3 w-15-px" />}
			hideSearch
		>
			{({ handleClickAway }) => (
				<Box className="position-relative">
					<Box className="position-absolute z-index-100 t-10">
						<DateRangePicker closeCalendar={() => handleClickAway()} dates={filterDataValue} updateDate={updateDate} open={true} {...DatePickerProps} />
					</Box>
				</Box>
			)}
		</CustomFilterWrapper>
	)
}

export default DatePickerFilterButton
