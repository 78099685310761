import * as React from "react"
import Card from "@mui/material/Card"
import CardActions from "@mui/material/CardActions"
import CardContent from "@mui/material/CardContent"
import CardMedia from "@mui/material/CardMedia"
import Button from "@mui/material/Button"
import Typography from "@mui/material/Typography"
import { Link } from "react-router-dom"
import { Box, useTheme } from "@mui/material"
import AccessTimeIcon from "@mui/icons-material/AccessTime"

const MediaCard = ({ link = "/", body, title, img, tag, duration, imageHeight = 150 }) => {
	const theme = useTheme()
	const tagBoxStyle = { padding: theme.spacing(1 / 4, 1 / 2), position: "absolute", top: 5, background: theme.palette.white.main, opacity: 0.9, borderRadius: 5, minWidth: "60px" }

	return (
		<Link className="txt-decoration-none h-100-percent" to={link}>
			<Card className="bg-white h-100-percent position-relative flex-column" elevation={0}>
				<CardMedia data-testid={"card-media"} component="img" image={img} alt="image" height={imageHeight} />
				{tag && (
					<Box className="l-5 txt-align-center" data-testid={"card-tag"} style={{ ...tagBoxStyle }}>
						{tag}
					</Box>
				)}
				{duration && (
					<Box className="r-5" data-testid={"card-duration"} style={{ ...tagBoxStyle }}>
						<AccessTimeIcon style={{ height: 16, verticalAlign: "text-bottom", fontSize: "1rem" }} />
						&nbsp;
						<Typography className="display-inline l--4" variant="p">
							{duration}
						</Typography>
					</Box>
				)}
				<CardContent className="txt-align-left flex-grow-1" data-testid={"card-body"}>
					<Typography gutterBottom variant="h4" component="div">
						{title}
					</Typography>
					<Typography className="txt-secondary" variant="body1">
						{body}
					</Typography>
				</CardContent>
				<CardActions className="justify-end">
					<Button size="small" className="txt-blue txt-transform-none">
						Learn more
					</Button>
				</CardActions>
			</Card>
		</Link>
	)
}

export default MediaCard
