import { createContext } from "react"
import { getStartEndDate } from "../util/util"
import { QueryClient, QueryClientProvider } from "@tanstack/react-query"

export const UserContext = createContext(null)
const queryClient = new QueryClient()

const MainContext = ({ children }) => {
	const store = {
		date: getStartEndDate(),
	}

	return (
		<QueryClientProvider client={queryClient}>
			<UserContext.Provider value={store}>{children}</UserContext.Provider>
		</QueryClientProvider>
	)
}

export default MainContext
