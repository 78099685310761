import { Typography } from '@mui/material'
import React from 'react'
import EnvBanner from '../../assets/banners/article4.png'
import { usePageTitle } from '../../hooks/TitleHook'
import ArticleLayout from '../../layout/ArticleLayout'

const Article4 = () => {
    usePageTitle("BlueHalo - Don't Tell Me")
    return (
        <ArticleLayout
            banner={EnvBanner}
            title="Don't Tell Me"
            subtitle="The management consultant and author Peter Drucker once said that ‘you can’t manage what you don’t measure’. The good news is that with BlueHalo you can do both."
        >
            <Typography variant='body1'>
                A few short years ago it was sufficient for businesses, largely as part of a marketing programme,
                to show climate action through staff planting days and special events tied to social climate action.
                Things are different today, as the climate challenge has grown so has the demand from investors, customers, staff,
                governments and other stakeholders to demonstrate carbon reduction efforts; to close the loop on carbon.
                These groups are wanting hard proof of performance and delivery against stated commitments, not wanting to take businesses at their word, they require evidence.
                This is where BlueHalo can remove the risk of errors from manual integrations and spreadsheets, and provide results in real time.
                <br /><br />
                This is where the deployment of BlueHalo technology is able to assist in the quantification of climate impact, the accurate measurement of that impact,
                and the evidence of reduction and offsetting. BlueHalo users are not only able to reduce their emissions,
                but to also select from an increasingly granular dataset of emissions calculations to reflect the differing emissions values from different
                modes and methods of activity but also providing the opportunity to reduce emissions in the first place.
                <br /><br />
                But what does that really mean? Consider the case for a business trip from Sydney in Australia to Dallas / Fort Worth in the United States.
                The options for travel include a direct service on an Airbus A380 or a Boeing 787. Assuming the same class of travel (Economy Class) is chosen,
                the Boeing 787 option results in a 30% reduction in emissions, or around a 0.3 tonne reduction in emissions,
                just through the selection of a more efficient and modern aircraft. If this choice is presented at the time of booking,
                it makes sense to choose the aircraft that produces less emissions.
                <br /><br />
                However, what other options are available assuming the above parameters remain but the flight times are more suitable on the Airbus A380? With BlueHalo,
                you can also pay to offset the emissions on all air travel, and the sum you pay is calculated based on the type of aircraft and the flight path.
                <br /><br />

                A direct integration of BlueHalo into the booking process enables the most efficient travel option to be booked and,
                once flown, will be recorded to ensure that reductions in emissions are tracked along with any residual emissions to be offset with credible carbon projects.
                For more information on what we do to validate and verify carbon projects please see our article entitled “Why due diligence on carbon offsets is critical for your strategy and brand.”
                <br /><br />
                This increasing resolution in emissions is not just confined to aircraft, it includes other travel and freight modes (sea, road, air and rail),
                electricity related emissions in built environments, and waste. This way your entire point to point journey is included in the calculation of your carbon impact,
                not just some of it. And, as mentioned above, BlueHalo can also reduce your electricity and waste emissions in addition to your travel.
                <br /><br />

                This increasing resolution is also important in the reporting of corporate emissions as emerging standards
                for example <a href='https://sciencebasedtargets.org/' className="sciencebasedtargets-link" target="_blank" rel="noopener noreferrer">Science Based Targets (SBTi)</a> and <a href='https://www.fsb-tcfd.org/' className="fsb-link" target="_blank" rel="noopener noreferrer"> The Task Force on Climate-related Financial Disclosures (TCFD)</a> require more and more detail on actual reduction and offsetting activities.
                Specialist audit and assurance capability from major professional services firms is rapidly being developed to respond to this emerging requirement from their corporate customers.
                When your organisation has the ability to communicate credibly the impact it is having on reducing emissions.
                and in supporting verified carbon projects, you assist in positioning your business as one that is serious about closing the loop on carbon.
                <br /><br />
                If you want to know more about our enhanced services then please contact us at <a href='mailto:support@ourbluehalo.com' className="support-link" target="_blank" rel="noopener noreferrer">support@ourbluehalo.com</a>
            </Typography>
        </ArticleLayout>
    )
}

export default Article4