import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import { trackEvent } from '../monitoring/tracking'

const { version } = require('../../package.json')

export const useCacheBuster = () => {
  const location = useLocation()
  const path = location.pathname.split("/").length === 2 ? "./" : "../" // TODO: if we go more than 2 layers deep on the location we will need to change this logic

  const parseVersion = (str) => +str.replace(/\D/g, '')

  useEffect(() => {
    fetch(`${path}meta.json?v=${+new Date()}`, { cache: 'no-cache' })
      .then((response) => response.json())
      .then((meta) => {
        if (meta?.version) {
          const metaVersion = parseVersion(meta.version);
          const packageVersion = parseVersion(version);
          if (packageVersion < metaVersion) {
            if (window?.location?.reload) {
              window.location.reload()
            }
          }
        }
      })
      .catch((error) => {
        console.error('something went wrong fetching meta.json', error)
        trackEvent("Cache busting error on meta.js fetch", {version : `${version}`})
      });
  }, [location])

  return null
}