import { useMsal } from "@azure/msal-react"
import { useSetAtom } from "jotai"
import { userAtom } from "../atoms/user"
import { trackEvent } from "../monitoring/tracking"
import { useLDClient } from "launchdarkly-react-client-sdk"

/* Set user data from MSAL login */
export const useUpdateUser = () => {
	const data = useMsal()
	const setUser = useSetAtom(userAtom)
	const ldClient = useLDClient()

	const { accounts } = data
	const given_name = accounts[0]?.idTokenClaims.given_name || "Customer"
	const family_name = accounts[0]?.idTokenClaims.family_name || ""
	const email = accounts[0]?.idTokenClaims.emails[0] || ""
	const isAdmin = accounts[0]?.idTokenClaims.extension_IsMajorTom || false

	const context = {
		kind: "user",
		key: `${email}`,
		name: `${given_name} ${family_name}`,
		email: `${email}`,
	}

	if (ldClient) ldClient.identify(context, null)

	setUser({ given_name, family_name, email, isAdmin })
	trackEvent("User authentication", { name: `${given_name} ${family_name}`, email: `${email}` })
}
