import { Box, Card, CardMedia, Typography } from "@mui/material"
import { Container } from "@mui/system"
import React from "react"

const ArticleLayout = ({ banner, title, subtitle, children }) => {
	return (
		<Box className="article">
			<Card sx={{ mr: -8, ml: -4 }}>
				<CardMedia component="img" height="auto" image={banner} />
			</Card>
			<Container sx={{ textAlign: "start", mb: 4, mt: 6 }}>
				<Typography variant="h3" sx={{ mb: 4 }}>
					{title}
				</Typography>
				<Typography variant="h4" sx={{ mb: 2 }}>
					{subtitle}
				</Typography>
				{children}
			</Container>
		</Box>
	)
}

export default ArticleLayout
