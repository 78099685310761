import { Box, ClickAwayListener } from "@mui/material"
import React, { useState } from "react"
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown"
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp"
import FilterWrapperButton from "./sub-components/FilterWrapperButton"
import FilterWrapperContainer from "./sub-components/FilterWrapperContainer"
import ClearIcon from "@mui/icons-material/Clear"
import styles from "../index.module.scss"

export default function CustomFilterWrapper({ label, leftIcon, children, className, valueSelected = false, clearFunction = null }) {
	const [anchorEl, setAnchorEl] = useState(null)

	const handleClick = (event) => {
		if (!anchorEl) {
			setAnchorEl(event.currentTarget)
		} else {
			setAnchorEl(null)
		}
	}

	return (
		<FilterWrapperContainer className={className} open={anchorEl !== null}>
			<FilterWrapperButton color="primary" component="label" onClick={handleClick} open={anchorEl !== null}>
				<Box data-testid="button-label" className="flex-row align-center">
					{leftIcon && leftIcon}
					<Box className="m-r-1-theme float-left">{label}</Box>
				</Box>
				{clearFunction && valueSelected && (
					<Box
						data-testid="clear-box"
						className={`${styles["clear-icon"]} m-l-1-theme`}
						onClick={(e) => {
							e.stopPropagation()
							e.preventDefault()
							clearFunction()
						}}
					>
						<ClearIcon style={{ width: "13px", color: "white", marginTop: -4 }} />
					</Box>
				)}
				{anchorEl ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
			</FilterWrapperButton>

			{anchorEl && (
				<ClickAwayListener onClickAway={handleClick}>
					<>{children({ handleClickAway: handleClick })}</>
				</ClickAwayListener>
			)}
		</FilterWrapperContainer>
	)
}
