import React, { useLayoutEffect, useState } from "react"
import { LegendOrdinal, LegendItem, LegendLabel } from "@visx/legend"
import Box from "@mui/material/Box"
import { useTheme } from "@mui/material"

function StackedBarLegendItem({ label, filters, index, filterData, parentWidth }) {
	const theme = useTheme()
	const [hover, setHover] = useState(false)
	const [legendFontSize, setLegendFontSize] = useState("0.85rem")
	const [dotSize, setDotSize] = useState(8)

	const legendItemIsFiltered = label.text in filters ? filters[label.text] === false : false

	useLayoutEffect(() => {
		if (parentWidth < 300) {
			setLegendFontSize("0.7rem")
			setDotSize(5)
		} else if (parentWidth < 500) {
			setLegendFontSize("0.5rem")
			setDotSize(8)
		} else {
			setLegendFontSize("0.85rem")
			setDotSize(8)
		}
	}, [parentWidth])

	return (
		<LegendItem
			key={`legend-item-${index}`}
			onMouseOver={() => setHover(true)}
			onMouseLeave={() => setHover(false)}
			margin={theme.spacing(0, 1.25)}
			onClick={() => filterData(label)}
			role={"legend-item"}
		>
			<svg className={hover || legendItemIsFiltered ? "opacity-40" : null} width={dotSize} height={dotSize}>
				<rect data-testid={`legend-dot-${index}`} fill={legendItemIsFiltered ? "grey" : label.value} width={dotSize} height={dotSize} rx={10} />
			</svg>
			<LegendLabel align="left" margin="0" className="txt-secondary font-semi-bold txt-transform-capitalize p-l-1-theme" style={{ fontSize: legendFontSize }}>
				{label.text}
			</LegendLabel>
		</LegendItem>
	)
}

export default function StackedBarLegend({ scale, filters, filterData, parentWidth }) {
	return (
		<LegendOrdinal scale={scale}>
			{(labels) => (
				<Box className="flex-row cursor-pointer">
					{labels.map((label, index) => (
						<StackedBarLegendItem key={index} label={label} filters={filters} index={index} filterData={filterData} parentWidth={parentWidth} />
					))}
				</Box>
			)}
		</LegendOrdinal>
	)
}
