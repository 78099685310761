import { useLayoutEffect } from "react"
import { BrowserRouter, Route, Routes, useLocation } from "react-router-dom"
import ProtectedPage from "./components/ProtectedPage/ProtectedPage"
import { useUpdateMode } from "./hooks/ModeHook"
import { useUpdateUser } from "./hooks/UserHooks"
import { useFilterAtom } from "./hooks/FilterHooks"
import { usePartnerAtom } from "./hooks/PartnerHooks"
import { useCacheBuster as UseCacheBuster } from "./hooks/useCacheBuster"
import { useAtomValue } from "jotai"
import { userAtom } from "./atoms/user"
import Layout from "./layout/Layout"
import GroundControl from "./pages/GroundControl"
import { withLDProvider } from "launchdarkly-react-client-sdk"
import { basicLogger } from "launchdarkly-js-client-sdk"

import { routeConfig } from "./util/RouteConfig"

const ScrollToTop = ({ children }) => {
	const location = useLocation()
	useLayoutEffect(() => {
		document.documentElement.scrollTo(0, 0)
	}, [location.pathname])
	return children
}

const Initialize = () => {
	useUpdateMode()
	useUpdateUser()
	useFilterAtom()
	usePartnerAtom()
}

const CustomRoutes = () => {
	const user = useAtomValue(userAtom)

	if (user.isAdmin) {
		routeConfig.push({ path: "/groundcontrol", element: <GroundControl />, showHeader: true })
	}

	return (
		<Routes>
			{routeConfig.map((route, i) => (
				<Route key={i} {...route} />
			))}
		</Routes>
	)
}

const routes = () => {
	return (
		<BrowserRouter>
			<ScrollToTop>
				<ProtectedPage>
					<Layout>
						<Initialize />
						<UseCacheBuster />
						<CustomRoutes />
					</Layout>
				</ProtectedPage>
			</ScrollToTop>
		</BrowserRouter>
	)
}

//export default routes
export default withLDProvider({
	clientSideID: window._env_.REACT_APP_LAUNCH_DARKLY_KEY,
	options: { logger: basicLogger({ level: "error" }) },
})(routes)
