import { atom } from "jotai"
import { getLocalStorageValueByKey, localStorageKeys, setLocalStorageValueByKey } from "../hooks/LocalStorageHooks"

const defaultMode = ""

export const getDefaultPartner = () => {
	let localStorageValue = getLocalStorageValueByKey(localStorageKeys.PARTNER_CODE)
	// Check that the value isn't empty
	if (localStorageValue && Object.keys(localStorageValue).length === 0 && Object.getPrototypeOf(localStorageValue) === Object.prototype) return defaultMode

	if (localStorageValue) return localStorageValue

	if (!localStorageValue) {
		localStorageValue = { ...localStorageValue }
		setLocalStorageValueByKey(localStorageKeys.PARTNER_CODE, localStorageValue)
	}

	return defaultMode
}

export const partnerAtom = atom(getDefaultPartner())