import { useState, useEffect } from "react"
import { Typography } from "@mui/material"
import { Box } from "@mui/system"
import ExpandableTable from "../../components/Table/TableConfigurations/ExpandableTable/ExpandableTable"
import { buildHeirarchicalDataForCostCentres } from "../../lib/convertions/EmissionsDataSet"
import TableWrapper from "../../components/Table/TableWrapper/TableWrapper"
import { sortByDate } from "../../lib/SortHelper"
import ExportButton from "../../components/ExportButton/ExportButton"
import { transformCostCentresToCSV } from "../../lib/ExportHelper"
import { setQueryParameters } from "../sub-components/SetQueryParameters"
import { usePageTitle } from "../../hooks/TitleHook"
import { LATEST_API_VERSION } from "../../util/Constants"
import LoadingSpinner from "../../components/Loading/LoadingSpinner"
import Alert from "@mui/material/Alert"
import { objectOfBoolsToCSV } from "../../util/util"
import ActivityFilterButton from "../../components/Filter/Wireups/ActivityFilterButton"

const dataTransformFunction = (data, filters) => {
	return buildHeirarchicalDataForCostCentres(data.data)
}

const CostCentres = () => {
	const [isFirstRender, setIsFirstRender] = useState(true)
	usePageTitle("BlueHalo - Cost Centre Analysis")
	const paramsFromAppState = setQueryParameters()

	// Reset if partner is changed via ground control
	useEffect(() => {
		setIsFirstRender(true)
	}, [paramsFromAppState.partners])

	return (
		<Box width="100%" height="100%">
			<TableWrapper
				queryData={{
					url: "/emissions/overview",
					method: "get",
					params: {
						"api-version": LATEST_API_VERSION,
						interval: "month",
						includeCostCentre: true,
						...paramsFromAppState,
					},
				}}
				dataTransformFunction={dataTransformFunction}
			>
				{({ transformedData, updateFilterValue, loaded, error }) => {
					const [hasCostCentres, setHasCostCentres] = useState(true)

					useEffect(() => {
						if (isFirstRender && transformedData.length) {
							setHasCostCentres(!(transformedData.length === 1 && transformedData[0].costCentre.code === "NULL"))
							setIsFirstRender(false)
						}
					}, [transformedData])

					return (
						<>
							<Box className={"p-lr-2-theme display-flex align-center wrap justify-between position-relative"} minHeight="50px">
								<Box>
									<Typography variant="h3" textAlign="start" className="txt-main m-b-0 txt-col-black">
										Cost Centre Analysis
									</Typography>
								</Box>
								{hasCostCentres && (
									<Box className="display-flex h-100-percent">
										<ActivityFilterButton onChange={(val) => updateFilterValue({ modes: objectOfBoolsToCSV(val) })} />
										<ExportButton
											fileType="text/csv"
											fileExtension="csv"
											transformData={transformCostCentresToCSV}
											data={transformedData}
											fileName={`Emissions By Cost Centre (${paramsFromAppState.dateFrom})-(${paramsFromAppState.dateTo})`}
										/>
									</Box>
								)}
							</Box>
							<Box width={"100%"} overflow="auto">
								{error ? (
									<Box className="flex-column position-relative w-100 m-2-theme">
										<Alert severity="error" data-testid={"error-component"}>
											{error}
										</Alert>
									</Box>
								) : (
									<>
										{loaded ? (
											<>
												{hasCostCentres ? (
													<ExpandableTable rows={transformedData} sortFunction={sortByDate} />
												) : (
													<Box className="p-l-2-theme txt-align-left">
														Looks like your integration isn't making use of our cost centre breakdown capabilities.{" "}
														<span className="txt-blue">
															<a href="mailto:help@ourbluehalo.com?subject=BlueHalo Cost Centre Integration">Contact support</a>
														</span>{" "}
														to learn more about you can utilise this functionality to enrich your reporting.
													</Box>
												)}
											</>
										) : (
											<Box className="flex-column position-relative" minHeight={200}>
												<LoadingSpinner />
											</Box>
										)}
									</>
								)}
							</Box>
						</>
					)
				}}
			</TableWrapper>
		</Box>
	)
}

export default CostCentres
