import { Box, PaginationItem } from "@mui/material"
import * as React from "react"
import Pagination from "@mui/material/Pagination"

export default function TablePagination({ onPageChange, currentPage, totalPages }) {
	return (
		<Box>
			<Pagination
				data-testid="pagination-container"
				defaultPage={currentPage}
				shape="rounded"
				siblingCount={1}
				boundaryCount={1}
				count={totalPages}
				onChange={onPageChange}
				renderItem={(i) => <PaginationItem data-testid={"pagination-item"} {...i} />}
			/>
		</Box>
	)
}
