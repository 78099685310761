import { trimArrayOfKeyValueObjects } from "../../util/util"

export function getRetiredOffsetsByCountryDataSet(retirements) {
	if (!retirements) return []
	const mappedData = retirements.map((data) => {
		return {
			key: data.country,
			value: data.emissionValue,
		}
	})

	return trimArrayOfKeyValueObjects(mappedData)
}

export function getRetiredOffsetsByProjectType(retirements) {
	if (!retirements) return []

	const mappedData = retirements.map((data) => {
		return {
			key: data.projectType,
			value: data.emissionValue,
		}
	})

	return trimArrayOfKeyValueObjects(mappedData)
}
