// SDG_ENDPOINT
import { useQueryEndpoint } from "../../hooks/QueryHooks"
import { SDG_ENDPOINT } from "../../util/Constants"
import { setQueryParameters } from "../sub-components/SetQueryParameters"
import _ from "lodash"
import { DisplaySingleModeTable } from "./displayTable"

export const SdgEndpoint2023_02_03 = () => {
    const queryString = setQueryParameters()
    const { data, error, loaded } = useQueryEndpoint(SDG_ENDPOINT, false, "get", queryString)

	if (loaded && !error) {
		const sdgs = data?.data?.sdgs || []       
        
		const sdgsTop10 = _.orderBy(sdgs, "projects.length", "desc")
			.filter((i) => i.projects.length > 0)
			.slice(0, 10)

        return (
            <>
                <DisplaySingleModeTable key="1" data={sdgs} title={"SDG All"} caption={`${SDG_ENDPOINT}`} totaller=""/>
                <DisplaySingleModeTable key="2" data={sdgsTop10} title={"SDG Top 10"} caption={`${SDG_ENDPOINT}`} totaller=""/>
            </>
        )
	}
    return <>Loading...</>
}