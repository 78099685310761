import { DataGrid } from "@mui/x-data-grid"
import { useState } from "react"
import { FaSort, FaSortDown, FaSortUp } from "react-icons/fa"
import styles from "./index.module.scss"

const CertificateGrid = ({ gridData, pageSize }) => {
	const [size, setSize] = useState(pageSize)
	const [sortModel, setSortModel] = useState([
		{
			field: "retirementDate",
			sort: "desc",
		},
	])

	const isGreaterThanPageSize = gridData.rows.length > pageSize ? true : false

	return (
		<DataGrid
			{...gridData}
			autoHeight
			pageSize={size}
			onPageSizeChange={(newPageSize) => setSize(newPageSize)}
			rowsPerPageOptions={isGreaterThanPageSize ? [pageSize, pageSize * 2, pageSize * 3] : []}
			disableSelectionOnClick
			sortModel={sortModel}
			onSortModelChange={(model) => setSortModel(model)}
			components={{
				ColumnSortedAscendingIcon: FaSortUp,
				ColumnSortedDescendingIcon: FaSortDown,
				ColumnUnsortedIcon: FaSort,
			}}
			hideFooter={!isGreaterThanPageSize}
			className="font-semi-bold txt-main border-0"
			getRowClassName={() => styles.cell}
		/>
	)
}

export default CertificateGrid
