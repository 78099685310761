import { FormGroup, FormControlLabel, RadioGroup, Radio } from "@mui/material"

export const DateRangeSelector = ({ handleChange, value }) => {
	return (
		<FormGroup>
			<RadioGroup row value={value} onChange={handleChange}>
				<FormControlLabel control={<Radio />} value="year" label="Last 12 months" />
				<FormControlLabel control={<Radio />} value="all" label="All" />
			</RadioGroup>
		</FormGroup>
	)
}
