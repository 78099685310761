import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import { Box } from "@mui/material"

const NotFound = () => {
	return (
		<Box>
			<h1>Did you get lost?</h1>
			<p className="font-size-20">
				<ArrowBackIcon className="vertical-align-bottom" /> Site navigation is over there
			</p>
		</Box>
	)
}

export default NotFound
