const { Chip, styled } = require("@mui/material")
import CloseIcon from "@mui/icons-material/Close"

const CustomChip = styled(Chip)(({ theme }) => ({
	textTransform: "none",
	borderRadius: "3px",
	background: theme.palette.skyBlue.secondary,
	marginRight: theme.spacing(0.5),
	fontSize: 12,
	color: theme.palette.white.main,
	padding: theme.spacing(0.5, 1),
	textAlign: "left",
	height: "100%",

	".MuiChip-label": {
		paddingLeft: theme.spacing(0),
		paddingRight: theme.spacing(1.5),
	},

	".MuiSvgIcon-root": {
		margin: 0,
		color: theme.palette.white.main,
		fontSize: "15px",
		fontWeight: "light",
	},
}))

export default function FilterWrapperButtonChip({ text, deleteItem }) {
	return <CustomChip data-testid={"filter-chip"} deleteIcon={<CloseIcon />} label={text} onDelete={deleteItem} />
}
