import { Box } from "@mui/material"
import Footer from "../components/Footer"
import Header from "../components/Header"
import MiniDrawer from "../components/MiniDrawer/MiniDrawer"
import { routeConfig } from "../util/RouteConfig"

const Layout = ({ children }) => {
	return (
		<Box className="main-grid">
			<Box className="sidebar-col border-r-1 border-col-light">
				<MiniDrawer />
			</Box>
			<Box className="main-col">
				<Box>
					<Box className="p-0" component="header">
						<Header routeConfig={routeConfig} />
					</Box>
					<Box className="p-b-1-theme p-l-3-theme p-t-0 p-r-5-theme" component="main">
						{children}
					</Box>
				</Box>
				<Box className="footer-layout bg-white">
					<Footer />
				</Box>
			</Box>
		</Box>
	)
}

export default Layout
