/* RETIREMENT_PROJECTS_ENDPOINT */
import { useQueryEndpoint } from "../../hooks/QueryHooks"
import { RETIREMENT_PROJECTS_ENDPOINT } from "../../util/Constants"
import { setQueryParameters } from "../sub-components/SetQueryParameters"
import { DisplaySingleModeTable } from "./displayTable"

export const RetirementProjectsEndpoint = () => {
	const body = setQueryParameters("POST")
	const { loaded, data, error } = useQueryEndpoint(RETIREMENT_PROJECTS_ENDPOINT, body)

	if (loaded && !error) {
		const projects = data?.data?.projects

		return <DisplaySingleModeTable data={projects} title={"Get Projects by Partner"} caption={`${RETIREMENT_PROJECTS_ENDPOINT}`} totaller="" />
	}
	return <>Loading...</>
}
