import { RETIREMENTS_BY_PROJECT_TYPE_ENDPOINT } from "../../../util/Constants"
import VisxChartWrapper from "../../ChartsVisX/shared/VisxChartWrapper"
import Donut from "../../ChartsVisX/ChartType/Donut/Donut"
import { palette } from "../../../theme"
import { getRetiredOffsetsByProjectType } from "../../../lib/convertions/RetirementDataSet"

const queryData = {
	url: RETIREMENTS_BY_PROJECT_TYPE_ENDPOINT,
}

const RetirementProjectType = () => {
	return (
		<VisxChartWrapper queryData={queryData} dataTransformFunction={(data) => getRetiredOffsetsByProjectType(data.data.retirements ?? [])}>
			{({ transformedData, width }) => (
				<Donut
					{...{
						containerWidth: width,
						containerHeight: width / 2,
						barColors: palette.charts.colorScale,
						title: "By climate technology",
						subTitle: null,
						unit: "tCO2e",
						data: transformedData,
					}}
				/>
			)}
		</VisxChartWrapper>
	)
}
export default RetirementProjectType
