/* RETIREMENTS_BY_COUNTRY_ENDPOINT */
import { useQueryEndpoint } from "../../hooks/QueryHooks"
import { RETIREMENTS_BY_COUNTRY_ENDPOINT } from "../../util/Constants"
import { setQueryParameters } from "../sub-components/SetQueryParameters"
import { DisplaySingleModeTable } from "./displayTable"

export const RetirementsByCountryEndpoint = () => {
	const body = setQueryParameters("POST")
	const { data, error, loaded } = useQueryEndpoint(RETIREMENTS_BY_COUNTRY_ENDPOINT, body)

	if (loaded && !error) {
		const retirementsData = data.data.retirements ?? []

		return <DisplaySingleModeTable data={retirementsData} title={"Retirements by Country"} caption={`${RETIREMENTS_BY_COUNTRY_ENDPOINT}`} totaller="emissionValue" />
	}
	return <>Loading...</>
}
