const { styled, MenuList } = require("@mui/material")

const FilterWrapperMenu = styled(MenuList)(({ theme }) => ({
	borderRadius: "0px 0px 4px 4px",
	position: "relative",
	padding: 0,
	background: "white",
	width: "100%",
	boxShadow: "0px 5px 10px 0px rgba(0,0,0,0.14)",
	zIndex: 100,
}))

export default FilterWrapperMenu
