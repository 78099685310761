import { RETIREMENTS_BY_COUNTRY_ENDPOINT } from "../../../util/Constants"
import { getRetiredOffsetsByCountryDataSet } from "../../../lib/convertions/RetirementDataSet"
import VisxChartWrapper from "../../ChartsVisX/shared/VisxChartWrapper"
import Donut from "../../ChartsVisX/ChartType/Donut/Donut"
import { palette } from "../../../theme"

const queryData = {
	url: RETIREMENTS_BY_COUNTRY_ENDPOINT,
}
const RetirementCountries = () => {
	return (
		<VisxChartWrapper queryData={queryData} dataTransformFunction={(data) => getRetiredOffsetsByCountryDataSet(data.data.retirements)}>
			{({ transformedData, width }) => (
				<Donut
					{...{
						containerWidth: width,
						containerHeight: width / 2,
						barColors: palette.charts.colorScale,
						title: "By source country",
						subTitle: null,
						unit: "tCO2e",
						data: transformedData,
						DonutLegendProps: { keyClasses: "txt-transform-capitalize" },
					}}
				/>
			)}
		</VisxChartWrapper>
	)
}
export default RetirementCountries
