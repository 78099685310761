import { Navigation, Pagination } from "swiper"
import { Swiper, SwiperSlide, useSwiper } from "swiper/react"
import "./index.scss"

// Import Swiper styles
import "swiper/css"
import "swiper/css/navigation"
import "swiper/css/pagination"
import "swiper/css/scrollbar"
import { IconButton } from "@mui/material"
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft"
import ChevronRightIcon from "@mui/icons-material/ChevronRight"
import { Box } from "@mui/system"

const SwiperButtons = () => {
	const swiper = useSwiper()

	return (
		<Box className="z-index-100 position-relative">
			<IconButton onClick={() => swiper.slidePrev()} className={"m-r-5-theme"}>
				<ChevronLeftIcon />
			</IconButton>
			<IconButton onClick={() => swiper.slideNext()}>
				<ChevronRightIcon />
			</IconButton>
		</Box>
	)
}

const Carousel = ({ children, slidesPerView = 3.3, swiperRef }) => {
	if (!children) return null
	const _slidesPerView = children.length > slidesPerView ? slidesPerView : children.length

	return (
		<Swiper
			ref={swiperRef ? swiperRef : undefined}
			slidesPerView={_slidesPerView}
			spaceBetween={20}
			loop={false}
			loopFillGroupWithBlank={false}
			pagination={{ clickable: true }}
			modules={[Pagination, Navigation]}
			className="mySwiper"
		>
			<SwiperButtons />
			{children.map((v, i) => (
				<SwiperSlide key={i}>{v}</SwiperSlide>
			))}
		</Swiper>
	)
}

export default Carousel
