import { rest } from "msw"
import * as chartData from "./ChartData.js"

export const handlers = [
	rest.post("*/emissions/km", (req, res, ctx) => {
		return res(
			ctx.json({
				data: {
					labels: ["5,000km annually", "10,000km annually", "15,000km annually", "20,000km annually", "30,000km annually"],
					data: [{ name: "Vehicle Emissions", rows: [5, 14, 18, 28, 33] }],
				},
			})
		)
	}),

	rest.post("*/emissions/vehicle", (req, res, ctx) => {
		return res(
			ctx.json({
				data: {
					labels: ["oversize", "Premium Economy", "Economy", "Standard", "Fullsize"],
					data: [{ name: "Vehicle Emissions", rows: [10, 34, 23, 45, 12] }],
				},
			})
		)
	}),

	rest.post("*/user/mode", (req, res, ctx) => {
		return res(
			ctx.json({
				data: {
					air: true,
					road: true,
					sea: false,
					rail: false,
				},
				success: true,
			})
		)
	}),

	rest.post("*/totals/flightKm", (req, res, ctx) => {
		return res(
			ctx.json({
				data: {
					air: { Distance: 26887653 },
					road: { Distance: 0 },
					sea: { Distance: 0 },
					rail: { Distance: 0 },
				},
				success: true,
			})
		)
	}),
]
