import { useQueryEndpoint } from "../../../hooks/QueryHooks"
import { SDG_ENDPOINT } from "../../../util/Constants"
import { CH9_UN_SDG } from "../../../lib/ChartsConfig"
import { getSDGSeries } from "../../../lib/convertions/UncDataSet"
import SeriesChart from "../../SeriesChart/SeriesChart"
import ChartWrapper from "../ChartWrapper"
import { getChartOptions, setChartOptions } from "../../../lib/HiChartHelper"
import { useFilterAtomValue } from "../../../hooks/FilterHooks"

const SDGChart = () => {
	const filterVal = useFilterAtomValue()
	const dateRange = `&dateFrom=${filterVal.dateFrom}&dateTo=${filterVal.dateTo}`
	const { data, error, loaded } = useQueryEndpoint(SDG_ENDPOINT + dateRange, false, "get")
	let charOptions = setChartOptions(CH9_UN_SDG, getChartOptions())
	if (loaded && !error) {
		const series = getSDGSeries(data?.data?.sdgs)
		charOptions["series"] = [
			{
				showInLegend: false,
				data: series,
			},
		]
	}

	return (
		<ChartWrapper loaded={loaded} error={error}>
			<SeriesChart isStockChart={false} options={charOptions} />
		</ChartWrapper>
	)
}
export default SDGChart
