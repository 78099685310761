import moment from "moment"
import ModeChip from "../../components/ModeChip"
import { Box } from "@mui/material"
import { prettifyCostCentre } from "../../util/util"
import { ACTIVITY_CHART_CONFIG } from "../../util/Constants"

export const emissionsLogResponseToTableData = (data, filters, pageSize) => {
	const response = data.data
	const d = response.logs
	let allItems = []

	for (const logItem of d) {
		let mode = logItem.mode
		let metadata = null
		let background = null

		switch (mode) {
			case "accommodation":
				metadata = generateAccommodationMetadataFromLog(logItem)
				background = ACTIVITY_CHART_CONFIG.accommodation.color
				break
			case "air":
				metadata = generateAirMetadataFromLog(logItem)
				background = ACTIVITY_CHART_CONFIG.air.color
				mode = "Flight"
				break
			case "road":
				metadata = generateRoadMetadataFromLog(logItem)
				background = ACTIVITY_CHART_CONFIG.road.color
				mode = "Road"
				break
			case "rail":
				metadata = generateRailMetadataFromLog(logItem)
				background = ACTIVITY_CHART_CONFIG.rail.color
				break
			case "sea":
				metadata = generateSeaMetadataFromLog(logItem)
				background = ACTIVITY_CHART_CONFIG.sea.color
				break
			default:
				break
		}

		const dateFormat = "D MMM YYYY h:mma"

		allItems.push({
			Date: logItem.transactionDate ? moment(logItem.transactionDate).format(dateFormat) : null,
			ActivityDate: logItem.activityDate ? moment(logItem.activityDate).format(dateFormat) : null,
			Mode: <ModeChip label={mode} background={background} />,
			Emissions: `${logItem.emissions.toFixed(3)}`,
			TransactionReferenceNumber: logItem.transactionReferenceNumber,
			Details: <span className="txt-blue font-semi-bold user-select-none">Details</span>,
			Metadata: metadata,
		})
	}

	return { logData: allItems, totalPages: Math.ceil(response.totalRows / pageSize), pageNumber: response.pageNumber }
}

export const generateAccommodationMetadataFromLog = (item) => {
	const { hotelChain, hotelName, roomNights, numberRooms, country, region, postcode, starRating, activityDate, costCentre } = item.metadata
	return (
		<Box data-testid="accommodation-meta" className="txt-main">
			<Box className="font-semi-bold">Additional properties</Box>
			<Box>Number of Rooms: {numberRooms ?? "-"}</Box>
			<Box>Number of Nights: {roomNights ?? "-"}</Box>
			<Box>Hotel: {hotelName ?? "-"} </Box>
			<Box>Hotel Chain: {hotelChain ?? "-"} </Box>
			<Box>Star Rating: {starRating ?? "-"}</Box>
			<Box>
				Region: {region} {postcode}, {country}
			</Box>
			<Box></Box>
			<Box>Cost Centre: {prettifyCostCentre(costCentre)}</Box>
		</Box>
	)
}

export const generateAirMetadataFromLog = (item) => {
	const {
		flightFrom,
		flightTo,
		passengers,
		travelClass,
		freightForwarder,
		freightWeight,
		aircraftType,
		airlineDesignator,
		freightAirlineDesignator,
		freightFlightDesignator,
		operatingAirlineDesignator,
		flightDesignation,
		destinationAirportName,
		sourceAirportName,
		sourceCountry,
		destinationCountry,
		distance,
		costCentre,
		freightUom,
	} = item.metadata

	const isPax = item.category === "passenger"

	return (
		<Box data-testid="air-meta" className="txt-main">
			<Box className="font-semi-bold">Additional properties</Box>
			<Box>
				Flight From: {sourceAirportName ?? "-"}
				{sourceAirportName && sourceCountry ? "," : ""} {sourceCountry} {flightFrom}
			</Box>
			<Box>
				Flight To: {destinationAirportName ?? "-"}
				{destinationAirportName && destinationCountry ? "," : ""} {destinationCountry} {flightTo}
			</Box>
			<Box>Aircraft Type: {aircraftType ?? "-"}</Box>
			<Box>Distance: {distance} km</Box>
			{isPax ? (
				<>
					<Box>Passengers: {passengers ?? "-"}</Box>
					<Box>Travel Class: {travelClass ?? "-"}</Box>
					<Box>Airline Designator: {airlineDesignator ?? "-"}</Box>
					<Box>Operating Airline Designator: {operatingAirlineDesignator ?? "-"}</Box>
					<Box>Flight Designator: {flightDesignation ?? "-"}</Box>
				</>
			) : (
				<>
					<Box>Freight Forwarder: {freightForwarder ?? "-"}</Box>
					<Box>Freight Weight: {freightWeight ? `${freightWeight} ${freightUom}` : "-"} </Box>
					<Box>Airline Designator: {freightAirlineDesignator ?? "-"}</Box>
					<Box>Flight Designator: {freightFlightDesignator ?? "-"}</Box>
				</>
			)}
			<Box>Cost Centre: {prettifyCostCentre(costCentre)}</Box>
		</Box>
	)
}

export const generateRoadMetadataFromLog = (item) => {
	const {
		fromCoord,
		toCoord,
		freightWeight,
		freightUom,
		vehicleType,
		vehicleSize,
		loadSize,
		fuel,
		distance,
		countryCode,
		passengerDistanceTierId,
		sippsAcrissMapId,
		tripType,
		usageType,
		vehicleGroup,
		vehicleMake,
		vehicleModel,
		vehicleYear,
		costCentre,
	} = item.metadata

	const isPax = item.category === "passenger"

	return (
		<Box data-testid="road-meta" className="txt-main">
			<Box className="font-semi-bold">Additional properties</Box>
			{isPax ? (
				<>
					<Box>
						Vehicle: {vehicleMake ?? "-"} {vehicleMake && vehicleModel ? "-" : ""} {vehicleModel} {vehicleYear ? `(${vehicleYear})` : ""}
					</Box>
					{fromCoord && toCoord && (
						<>
							<Box>From Coords: {fromCoord ?? "-"} </Box>
							<Box>To Coords: {toCoord ?? "-"} </Box>
						</>
					)}
					{distance && (
						<>
							<Box>Distance {distance ?? "-"} </Box>
						</>
					)}
					<Box>Usage Type: {usageType ?? "-"}</Box>
				</>
			) : (
				<>
					<Box>Vehicle Type: {vehicleType ?? "-"}</Box>
					<Box>Vehicle Size: {vehicleSize ?? "-"}</Box>
					<Box>Load Size: {loadSize ?? "-"}</Box>
					<Box>Fuel: {fuel ?? "-"}</Box>
					<Box>Freight Weight: {freightWeight ? `${freightWeight} ${freightUom}` : "-"} </Box>
					<Box>Trip Type: {tripType ?? "-"}</Box>
				</>
			)}
			<Box>Distance: {distance}</Box>
			<Box>Cost Centre: {prettifyCostCentre(costCentre)}</Box>
		</Box>
	)
}

export const generateSeaMetadataFromLog = (item) => {
	const {
		shipName,
		shipMode,
		shipType,
		shipSize,
		imoCode,
		freightUom,
		freightWeight,
		fromPort,
		toPort,
		sourcePortName,
		sourceCountry,
		destinationPortName,
		destinationCountry,
		isInternational,
		distance,
		costCentre,
	} = item.metadata

	return (
		<Box data-testid="sea-meta" className="txt-main">
			<Box className="font-semi-bold">Additional properties</Box>
			<Box>
				Voyage From: {sourcePortName} {fromPort}
				{sourcePortName && sourceCountry ? "," : ""} {sourceCountry}
			</Box>
			<Box>
				Voyage To: {destinationPortName} {toPort}
				{destinationPortName && destinationCountry ? "," : ""} {destinationCountry}
			</Box>
			<Box>Distance: {distance} km</Box>
			<Box>Ship Name: {shipName ?? "-"}</Box>
			<Box>Ship Size: {shipSize ?? "-"}</Box>
			<Box>Ship Type: {shipType ?? "-"}</Box>
			<Box>Ship Mode: {shipMode ?? "-"}</Box>
			<Box>Freight Weight: {freightWeight ? `${freightWeight} ${freightUom}` : "-"} </Box>
			<Box>IMO Code: {imoCode ?? "-"}</Box>
			<Box>Cost Centre: {prettifyCostCentre(costCentre)}</Box>
		</Box>
	)
}

export const generateRailMetadataFromLog = (item) => {
	const {
		serviceProvider,
		railDesignator,
		freightForwarder,
		freightUom,
		freightWeight,
		sourceStationName,
		sourceCountryName,
		destinationStationName,
		destinationCountryName,
		isInternational,
		distance,
		costCentre,
	} = item.metadata

	return (
		<Box data-testid="rail-meta" className="txt-main">
			<Box className="font-semi-bold">Additional properties</Box>
			<Box>
				Journey From: {sourceStationName}
				{sourceStationName && sourceCountryName ? "," : ""} {sourceCountryName}
			</Box>
			<Box>
				Journey To: {destinationStationName}
				{destinationStationName && destinationCountryName ? "," : ""} {destinationCountryName}
			</Box>
			<Box>Distance: {distance} km</Box>
			<Box>Freight Forwarder: {freightForwarder ?? "-"}</Box>
			<Box>Freight Weight: {freightWeight ? `${freightWeight} ${freightUom}` : "-"} </Box>
			<Box>Rail Designator: {railDesignator ?? "-"}</Box>
			<Box>Service Provider: {serviceProvider ?? "-"}</Box>
			<Box>Cost Centre: {prettifyCostCentre(costCentre)}</Box>
		</Box>
	)
}
