import { Table, TableBody, TableCell, TableRow } from "@mui/material"
import { Box } from "@mui/system"
import { useState } from "react"
import { useLayoutEffect } from "react"
import { useTheme } from "@mui/material/styles"

const innerBoxStyle = { position: "relative" }
const tableCellClassName = "p-4 border-0"
const dotSize = 8
const DEFAULT_TEXT_TRANSFORM = "txt-transform-capitalize-first"

export default function DonutLegend({ outerBoxStyle, setHoverKey, hoverKey, decimalPoints = 0, total, data, unit, getColor, parentWidth, keyClasses = DEFAULT_TEXT_TRANSFORM }) {
	const theme = useTheme()
	const [legendFontSize, setLegendFontSize] = useState("1rem")

	const getRatio = (value) => {
		const divided = (value / total) * 100
		return divided ? divided.toFixed(decimalPoints) : 0
	}

	useLayoutEffect(() => {
		if (parentWidth < 300) {
			setLegendFontSize("0.8rem")
		} else if (parentWidth < 500) {
			setLegendFontSize("0.75rem")
		} else {
			setLegendFontSize("1rem")
		}
	}, [parentWidth])

	return (
		<Box style={{ ...outerBoxStyle }}>
			<Box style={innerBoxStyle}>
				<Table>
					<TableBody>
						{data.map((el, index) => {
							let iconFill = getColor(el.key)
							const elKey = `${el.key}:`.toLowerCase()

							let tableCellClass = `${tableCellClassName} ${elKey.includes("other") ? DEFAULT_TEXT_TRANSFORM : keyClasses}`

							if (hoverKey !== null) {
								if (hoverKey !== el.key) {
									tableCellClass = `${tableCellClass} opacity-50`
									iconFill = "lightgrey"
								}
							}

							return (
								<TableRow key={index} onMouseOver={() => setHoverKey(el.key)} onMouseLeave={() => setHoverKey(null)} data-testid={"legend-item"}>
									<TableCell className={tableCellClass} fontSize={legendFontSize}>
										<svg width={dotSize} height={dotSize}>
											<rect data-testid={`legend-dot-${index}`} fill={iconFill} width={dotSize} height={dotSize} rx={10} />
										</svg>
									</TableCell>
									<TableCell className={`${tableCellClass} p-r-2-theme`} fontSize={legendFontSize} align="left">
										{elKey}
									</TableCell>
									<TableCell className={`${tableCellClass} txt-transform-none`} fontSize={legendFontSize} align="left">
										{`${getRatio(el.value)}%`}&nbsp;({el.value.toFixed(0).toLocaleString("en")}&nbsp;{unit})
									</TableCell>
								</TableRow>
							)
						})}
					</TableBody>
				</Table>
			</Box>
		</Box>
	)
}
