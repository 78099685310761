import { Avatar, Box, Stack, Typography } from "@mui/material"
import BCorp from "../../assets/logos/B-Corp-Logo-Black-RGB.png"
import ACI from "../../assets/logos/ACI_Code_Signatory_RGB.png"
import BlueHaloIcon from "../../assets/logos/bluehalo-tem.png"
import { TiSocialFacebook, TiSocialLinkedin, TiSocialTwitter } from "react-icons/ti"
import { FACKBOOK_URL, LINKEDIN_URL, TWITTER_URL } from "../../util/Constants"
import "./style.css"
import { palette } from "../../theme"

const SocialMedia = () => {
	const iconSx = {
		backgroundColor: "white",
		height: 28,
		width: 28,
		paddingTop: "3px",
	}

	return (
		<Stack direction="row" spacing={1} className="socialMediaIcons">
			<Avatar sx={iconSx}>
				<a href={LINKEDIN_URL} className="linkedin-icon" target="_blank" rel="noopener noreferrer">
					<TiSocialLinkedin color="white" />
				</a>
			</Avatar>
			<Avatar sx={iconSx}>
				<a href={FACKBOOK_URL} className="facebook-icon" target="_blank" rel="noopener noreferrer">
					<TiSocialFacebook color="white" />
				</a>
			</Avatar>
			<Avatar sx={iconSx}>
				<a href={TWITTER_URL} className="twitter-icon" target="_blank" rel="noopener noreferrer">
					<TiSocialTwitter color="white" />
				</a>
			</Avatar>
		</Stack>
	)
}

const LeftSide = () => (
	<Stack spacing={2} alignItems="start">
		<SocialMedia />
		<div className="logos">
			<img src={BCorp} width="auto" height="50px" />
			<img src={ACI} width="auto" height="50px" />
		</div>
		<Typography variant="caption" color="skyBlue.mid">
			© Copyright {new Date().getFullYear()} BlueHalo
		</Typography>
	</Stack>
)

const linkProps = {
	target: "_blank",
	rel: "noreferrer",
	style: {
		textDecoration: "none",
		color: palette.skyBlue.mid,
	},
}

const LeftRight = () => (
	<Stack spacing={1} alignItems="end">
		<a href="https://ourbluehalo.com/privacypolicy" {...linkProps}>
			<Typography variant="caption" color="black.main" sx={{ textDecoration: "underline" }} display="inline">
				Privacy policy
			</Typography>
		</a>
		<a href="https://ourbluehalo.com/tc" {...linkProps}>
			<Typography variant="caption" color="black.main" sx={{ textDecoration: "underline" }} display="inline">
				Terms of service
			</Typography>
		</a>
		<img src={BlueHaloIcon} height="auto" width="250px" />
	</Stack>
)

const Footer = () => {
	const gridContainer = {
		display: "grid",
		gridTemplateColumns: "repeat(2, 1fr)",
		marginLeft: "auto",
	}

	const gridItem = {
		display: "flex",
		flexDirection: "column",
		padding: 1,
		mt: 3,
	}

	return (
		<Box data-testid="footer-parent" sx={gridContainer}>
			<Box sx={{ ml: 2, ...gridItem }}>
				<LeftSide />
			</Box>
			<Box sx={{ mr: 5, ...gridItem }}>
				<LeftRight />
			</Box>
		</Box>
	)
}

export default Footer
