import { Container, Typography } from "@mui/material"
import { Box } from "@mui/system"
import SDGProjectCarousel from "../components/SDGProjectCard"

const Portfolio = () => {
	return (
		<Box>
			<Container className="m-l-0 p-l-0">
				<Typography className="txt-main m-b-0" variant="h3" textAlign="start">
					Portfolio {"&"} Projects
				</Typography>
				<Typography className="m-b-4-theme" variant="body1" textAlign="start">
					Projects are grouped into categories based on the technology and methods used to generate carbon offsets.
				</Typography>
			</Container>
			<Box display="block">
				<SDGProjectCarousel />
			</Box>
		</Box>
	)
}

export default Portfolio
