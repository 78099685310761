const { Box, styled } = require("@mui/material")

const FilterWrapperContainer = styled(Box)(({ theme }) => ({
	minWidth: "16px",
	boxShadow: theme.shadows[2],
	borderRadius: "4px",
	position: "relative",
	background: "white",
	height: "36px",
}))

export default FilterWrapperContainer
