import { PublicClientApplication } from "@azure/msal-browser"
import { MsalProvider } from "@azure/msal-react"
import "./App.css"
import "./styles/main.scss"
import { msalConfig } from "./authConfig"
import MainContext from "./context"
import Routes from "./routes"
import ThemeWrapper from "./theme"

const App = () => {
	const msalInstance = new PublicClientApplication(msalConfig)
	return (
		<div className="App">
			<MsalProvider instance={msalInstance}>
				<ThemeWrapper>
					<MainContext>
						<Routes />
					</MainContext>
				</ThemeWrapper>
			</MsalProvider>
		</div>
	)
}

export default App
