import Table from "@mui/material/Table"
import TableBody from "@mui/material/TableBody"
import TableCell from "@mui/material/TableCell"
import TableHead from "@mui/material/TableHead"
import TableRow from "@mui/material/TableRow"
import TableFooter from "@mui/material/TableFooter"
import TableContainer from "@mui/material/TableContainer"

export const DisplaySingleModeTable = ({data, title, caption, totaller}) => {
    if(data.length === 0) { return null }

    let total = 0
    const titleArray = Object.keys(data[0])
    const titleRow = <TableRow>{titleArray.map(row => <TableCell>{row}</TableCell>)}</TableRow>

    const dataList = data.map((row, i) => {
        total += row[totaller]
        let rowArray = []
        for (let key in row) {
            rowArray.push(<TableCell>{row[key]}</TableCell>)
        }
        return <TableRow key={i}>{rowArray.map(row => row)}</TableRow>
    })
    return (
        <>
            <h3>{title}</h3>
            <h4>{caption}</h4>
            <TableContainer>
                <Table key={title}>
                    <TableHead>
                        {titleRow}
                    </TableHead>
                    <TableBody>                
                        {dataList}
                    </TableBody>
                    {totaller && (
                    <TableFooter>
                        <TableRow><TableCell><b>{total}</b></TableCell></TableRow>
                    </TableFooter>
                    )}
                </Table>
            </TableContainer>
        </>
    )
}