import { Box } from "@mui/material"
import React from "react"
import PropTypes from "prop-types"

const CssGrid = ({ sx, gap = 1, container = false, children, columnTemplate, rowTemplate, debug = false }) => {
	if (container) {
		return (
			<Box
				sx={{
					display: "grid",
					gridTemplateColumns: columnTemplate,
					gridTemplateRows: rowTemplate,
					gap: gap,
					...(debug ? { outline: "2px solid green !important" } : null),
					...sx,
				}}
			>
				{children}
			</Box>
		)
	} else {
		return (
			<Box
				sx={{
					display: "flex",
					flexDirection: "column",
					...(debug ? { outline: "1px solid red !important" } : null),
					...sx,
				}}
			>
				{children}
			</Box>
		)
	}
}

CssGrid.propTypes = {
	sx: PropTypes.object,
	gap: PropTypes.number,
	children: PropTypes.node,
	columnTemplate: PropTypes.string,
	rowTemplate: PropTypes.string,
	debug: PropTypes.bool,
	container: PropTypes.bool,
}

export default CssGrid
