import { useState } from "react"
import { useQueryEndpoint } from "../../hooks/QueryHooks"
import { usePartnerAtom } from "../../hooks/PartnerHooks"
import { localStorageKeys, setLocalStorageValueByKey } from "../../hooks/LocalStorageHooks"
import { Paper, styled, useTheme } from "@mui/material"
import { Box } from "@mui/system"
import ExpandLess from "@mui/icons-material/ExpandLess"
import ExpandMore from "@mui/icons-material/ExpandMore"

const FloatingElementContainer = styled("div")(() => ({
	background: "transparent",
	position: "relative",
}))

const InnerAbsoluteDiv = styled("div")(({ theme }) => ({
	position: "absolute",
	zIndex: 999,
	width: "100%",
	padding: theme.spacing(0, 5),
	top: "44px",
	right: "48px",
}))

export const PartnerSelector = () => {
	const theme = useTheme()
	const [partner, setPartner] = usePartnerAtom()
	const [open, setOpenOptions] = useState(false)
	const { data, error, loaded } = useQueryEndpoint("/user/partnerCode", false, "get")

	const updatePartner = (selectedPartner) => {
		setPartner(selectedPartner)
		setLocalStorageValueByKey(localStorageKeys.PARTNER_CODE, selectedPartner)
	}

	if (loaded && !error) {
		const partnerArray = data?.data || []

		return (
			<Box className="border-radius-4 cursor-pointer border-1 p-1-theme" boxShadow={theme.shadows[2]} onClick={() => setOpenOptions(!open)} height="35px" width="250px" marginRight="15px">
				<Box className="float-left" display={"inline"}>
					{partner ? partner : "Select"}
				</Box>
				<Box className="float-right" display="inline">
					{!open ? <ExpandMore className="p-b-4" /> : <ExpandLess className="p-b-4" />}
				</Box>
				<OptionDropdown open={open} updatePartner={updatePartner} partnerArray={partnerArray} />
			</Box>
		)
	}
	return <>Loading...</>
}

const OptionDropdown = ({ open, updatePartner, partnerArray }) => {
	if (!open) return null

	return (
		<FloatingElementContainer>
			<InnerAbsoluteDiv>
				<Paper className="overflow-hidden" data-testid={"option-picker"} style={{ width: "250px", maxWidth: "100vw" }}>
					{partnerArray
						.sort((a, b) => (a < b ? -1 : 1))
						.map((row, index) => (
							<Box
								className={`p-tb-1-theme p-lr-2-theme cursor-pointer txt-main bg-white bg-hover-grey border-b-1 border-col-light"`}
								data-testid={"partner"}
								onClick={() => updatePartner(row)}
								height={35}
							>
								{row}
							</Box>
						))}
					<Box
						className={"p-tb-1-theme p-lr-2-theme cursor-pointer txt-main bg-white bg-hover-grey border-b-1 border-col-light"}
						data-testid={"partner"}
						onClick={() => updatePartner("")}
						height={35}
					>
						Clear
					</Box>
				</Paper>
			</InnerAbsoluteDiv>
		</FloatingElementContainer>
	)
}
