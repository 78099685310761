import * as React from "react"
import { Box, Typography } from "@mui/material"
import SouthEastIcon from "@mui/icons-material/SouthEast"
import NorthEastIcon from "@mui/icons-material/NorthEast"
import { numberToPositionalNotation } from "../../util/util"
import IconTooltip from "../IconTooltip"
import EastIcon from "@mui/icons-material/East"

function TrendIcon({ color, height, width, trendIcon }) {
	return (
		<Box className="m-r-5 m-t-6 border-radius-7 txt-align-center float-left" data-testid={"trend-icon"} backgroundColor={color} lineHeight="1.4rem" width={width} height={height}>
			{trendIcon}
		</Box>
	)
}

export default function KPICards({ title, value = null, unit = null, icon = null, trend = null, trendUnit, trendSymbol = "", tooltipContent = null }) {
	return (
		<Box className="flex-row justify-center" position="relative" height="100%">
			<Box className="align-self-center h-100-percent w-100-percent">
				<Box width="100%" overflow="auto">
					<Typography className="txt-secondary float-left m-b-1-theme font-size-14" data-testid={"title"} variant="h5" component="p">
						{title}
					</Typography>
					{tooltipContent && <IconTooltip tooltipContent={tooltipContent} />}
				</Box>

				<Box className="overflow-hidden m-b-1-theme" data-testid={"value-and-unit"} lineHeight="1.75rem">
					<Typography className="txt-main float-left p-r-7" variant="bold" component="b" fontSize={"1.75rem"}>
						{value !== null ? numberToPositionalNotation(value, 1) : <Box>-</Box>}
					</Typography>

					{unit !== null && value !== null && (
						<Typography className="m-t-4 float-left txt-secondary" variant="p" fontSize={"1rem"}>
							{unit}
						</Typography>
					)}

					{icon && (
						<Box data-testid={"icon"} className="r-0" position="absolute">
							{icon}
						</Box>
					)}
				</Box>
				{trend !== null && (
					<Box className="overflow-hidden" lineHeight="1.75rem">
						<TrendIcon
							trendIcon={trend > 0 ? <NorthEastIcon className="font-size-15" /> : trend == 0 ? <EastIcon className="font-size-15" /> : <SouthEastIcon className="font-size-15" />}
							color={trend > 0 ? "#FFE4E6" : trend == 0 ? "#EBEBEB" : "#D1FAE5"}
							height={"1rem"}
							width={"2rem"}
						/>
						<Typography className="txt-secondary p-r-5 opacity-60 font-size-13 float-left" data-testid={"trend-text"} variant="p">
							{`${trend.toLocaleString("en").replace("-", "")}${trendSymbol}`}
						</Typography>
						<Typography className="txt-secondary font-size-13 float-left" data-testid={"trend-unit"} variant="h4" lineHeight="1.75rem">
							{trendUnit}
						</Typography>
					</Box>
				)}
			</Box>
		</Box>
	)
}
