import { getValueFromNestedObject } from "./convertions/EmissionsDataSet"

export function getEmissionsSumByKeysFromObject(items, props) {
	return items.reduce(function (a, b) {
		return (
			a +
			props.reduce(function (c, d) {
				return c + (getValueFromNestedObject(b, d) ?? 0)
			}, 0)
		)
	}, 0)
}

export function getEmissionsSumByKeysFromArray(items, modes, emissionKey) {
	let val = 0
	modes.forEach(function (key) {
		val += items[key].reduce(function (a, b) {
			return a + (getValueFromNestedObject(b, emissionKey) ?? 0)
		}, 0)
	})
	return val
}

export function calculateTrend(thisData, lastData) {
	if (thisData === 0) {
		if (lastData > 0) return -100
		if (lastData < 0) return 100
		if (lastData === 0) return 0
	} else if (lastData === 0) {
		if (thisData > 0) return 100
		if (thisData < 0) return -100
	}
	return (((thisData - lastData) / lastData) * 100).toFixed(0)
}

export function transformArrayOfArraysForKPICard(dataArray, modes, key = "emissions") {
	if (!dataArray[0] || !dataArray[1]) {
		return { value: null, trend: null }
	}

	const overallEmissions = { this: getEmissionsSumByKeysFromArray(dataArray[0].data, modes, key), last: getEmissionsSumByKeysFromArray(dataArray[1].data, modes, key) }
	return { value: overallEmissions.this.toFixed(0), trend: calculateTrend(overallEmissions.this, overallEmissions.last) }
}

export function transformArrayOfObjectsForKPICard(dataArray, modes) {
	if (!dataArray[0] || !dataArray[1]) {
		return { value: null, trend: null }
	}

	const overallEmissions = { this: getEmissionsSumByKeysFromObject([dataArray[0].data], modes), last: getEmissionsSumByKeysFromObject([dataArray[1].data], modes) }
	return { value: overallEmissions.this.toFixed(0), trend: calculateTrend(overallEmissions.this, overallEmissions.last) }
}
