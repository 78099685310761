import { useAtom, useAtomValue, useSetAtom } from "jotai"
import { modeAtom, modeListAtom } from "../atoms/mode"
import { useQueryEndpoint } from "./QueryHooks"
import { CUSTOMER_MODE_ENDPOINT, LATEST_API_VERSION } from "../util/Constants"
import { trackEvent } from "../monitoring/tracking"
import { setQueryParameters } from "../pages/sub-components/SetQueryParameters"

export const useModeList = () => useAtomValue(modeListAtom)

export const useUpdateMode = () => {
	const params = setQueryParameters()
	const { data, error, loaded } = useQueryEndpoint(CUSTOMER_MODE_ENDPOINT, null, "GET", { "api-version": LATEST_API_VERSION, ...params })
	const setMode = useSetAtom(modeAtom)

	if (loaded && !error) {
		const responseObj = data.data
		const isTravelPartner = Object.values(responseObj).some((subObj) => subObj.passenger === true)
		const isFreightPartner = Object.values(responseObj).some((subObj) => subObj.freight === true)
		const modeData = { modes: responseObj, isTravelPartner, isFreightPartner }
		setMode(modeData)
		trackEvent("Set Mode", modeData)
	}
}
