import { Table, TableCell, TableHead, TableBody, TableRow, Typography, Box } from "@mui/material"
import { useQueryEndpoint } from "../../hooks/QueryHooks"
import { RETIREMENTS_CERTIFICATES_ENDPOINT } from "../../util/Constants"
import { setQueryParameters } from "../../pages/sub-components/SetQueryParameters"
import { getCertificateDownloadUrl } from "../../util/util"
import AuthenticatedLink from "../AuthenticatedLink"
import LoadingWrapper from "../Loading/LoadingWrapper"
import SkeletonRetiredCerts from "./SkeletonRetiredCerts"
import moment from "moment"

export const CertItems = (props) => {
	const { key, dwUrl, date, displayName, filename, lastItem } = props
	const className = `p-l-1-theme ${lastItem ? "border-0" : ""}`

	return (
		<TableRow data-testid={`cert-item`} key={filename}>
			<TableCell className={className}>{displayName}</TableCell>
			<TableCell className={className}>{moment(date).format("D MMM YYYY")}</TableCell>
			<TableCell className={className}>
				<AuthenticatedLink url={dwUrl} filename={filename}>
					Download
				</AuthenticatedLink>
			</TableCell>
		</TableRow>
	)
}

export function getCertFromData(certs) {
	if (!certs)
		return (
			<Typography variant="body1" data-testid="typography-cert-data">
				You have no retirement certificates available yet. When retirements are completed your certificates will appear below.
			</Typography>
		)
	const className = "border-0 p-l-1-theme p-b-0"

	return (
		<Box data-testid="cert-from-data">
			<Typography className="p-l-1-theme" variant="h4">
				Retirement Certificates
			</Typography>
			<Table>
				<TableHead>
					<TableRow>
						<TableCell className={className}>
							<Typography variant="bold">Report date </Typography>
						</TableCell>
						<TableCell className={className}>
							<Typography variant="bold">Created date </Typography>
						</TableCell>
						<TableCell className={className}>
							<Typography variant="bold">Report </Typography>
						</TableCell>
					</TableRow>
				</TableHead>
				<TableBody>{createCertList(certs, 5)}</TableBody>
			</Table>
		</Box>
	)
}

export function createCertList(cert = [], take = 5) {
	if (!cert) return undefined
	const list = cert
		.slice(0, take)
		.sort((a, b) => (new Date(a.retirementDate) > new Date(b.retirementDate) ? -1 : 1))
		.map((i, index) => ({
			key: index,
			id: i.id,
			dwUrl: getCertificateDownloadUrl(i.id),
			date: i.retirementDate?.substring(0, 10),
			filename: i.filename,
			partnerCode: i.partnerCode,
			displayName: i.displayName,
			lastItem: index === cert.length - 1,
		}))

	return list.map((i) => CertItems(i))
}

const RetiredCerts = () => {
	const body = setQueryParameters("POST")
	const { data, error, loaded } = useQueryEndpoint(RETIREMENTS_CERTIFICATES_ENDPOINT, body)
	return (
		<LoadingWrapper loaded={loaded} error={error} loadingComponent={<SkeletonRetiredCerts />}>
			{getCertFromData(data?.data?.certificates)}
		</LoadingWrapper>
	)
}
export default RetiredCerts
