import styled from "@emotion/styled"
import { Paper } from "@mui/material"

const Item = styled(Paper)(({ theme, sx }) => ({
	background: theme.palette.white.main,
	...theme.typography.body1,
	padding: theme.spacing(2),
	textAlign: "start",
	color: theme.palette.text.main,
	margin: 0,
	position: "relative",
	...sx,
}))

export default Item
