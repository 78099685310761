import { createSvgIcon } from "@mui/material/utils"
import * as React from "react"

export const IcLogout = createSvgIcon(
	<svg width="27" height="27" viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path
			d="M3.75 3.49369C3.55109 3.49369 3.36032 3.57298 3.21967 3.71412C3.07902 3.85525 3 4.04668 3 4.24628V14.7824C3 14.982 3.07902 15.1735 3.21967 15.3146C3.36032 15.4557 3.55109 15.535 3.75 15.535H6.75C7.16421 15.535 7.5 15.872 7.5 16.2876C7.5 16.7032 7.16421 17.0402 6.75 17.0402H3.75C3.15326 17.0402 2.58097 16.8023 2.15901 16.3789C1.73705 15.9555 1.5 15.3812 1.5 14.7824V4.24628C1.5 3.64748 1.73705 3.07322 2.15901 2.6498C2.58097 2.22639 3.15326 1.98853 3.75 1.98853H6.75C7.16421 1.98853 7.5 2.32547 7.5 2.74111C7.5 3.15675 7.16421 3.49369 6.75 3.49369H3.75Z"
			fill="#637381"
		/>
		<path
			d="M11.4697 5.21928C11.7626 4.92538 12.2374 4.92538 12.5303 5.21928L16.2803 8.9822C16.5732 9.2761 16.5732 9.75261 16.2803 10.0465L12.5303 13.8094C12.2374 14.1033 11.7626 14.1033 11.4697 13.8094C11.1768 13.5155 11.1768 13.039 11.4697 12.7451L14.6893 9.51436L11.4697 6.2836C11.1768 5.9897 11.1768 5.51319 11.4697 5.21928Z"
			fill="#637381"
		/>
		<path
			d="M6 9.51436C6 9.09872 6.33579 8.76178 6.75 8.76178H15.75C16.1642 8.76178 16.5 9.09872 16.5 9.51436C16.5 9.93 16.1642 10.2669 15.75 10.2669H6.75C6.33579 10.2669 6 9.93 6 9.51436Z"
			fill="#637381"
		/>
	</svg>,
	"IcLogout"
)
