import React from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import HighchartsStock from 'highcharts/highstock';
import HighchartsExport from 'highcharts/modules/exporting'
import  HighchartsOfflineExport from 'highcharts/modules/offline-exporting'
import Drilldown from "highcharts/modules/drilldown.js";
import "./Index.css"

HighchartsExport(Highcharts)
HighchartsOfflineExport(Highcharts)
Drilldown(Highcharts)

const SeriesChart = ({ options, isStockChart } ) => {
    return (

            (isStockChart
                   ? <HighchartsReact highcharts={HighchartsStock} constructorType={'stockChart'} options={options} />
                   : <HighchartsReact highcharts={Highcharts} options={options} />)

    )
}
export default SeriesChart;