import _ from "lodash"

function formatImg(imgSrc, width = 50) {
	return `<span><img  class='chart-label-img' src='${imgSrc}' style="width: ${width}px; height: ${width}px; margin-left:0px; opacity: 0.6;"/></span>`
}

export function getSDGSeries(offests = []) {
	if (!offests) return []
	const series = []
	for (const entry of _.orderBy(offests, "sdgId")) {
		series.push({
			color: "#" + entry.sdgColour,
			name: formatImg(entry.sdgUrl, 50),
			y: entry.projects.length,
			custom: {
				number: entry.sdgId,
				description: entry.description,
				projects: entry.projects.map(project => `<br /><b>${project}</b>`),
				tooltipText: entry.projects.length > 1 ? "projects contribute to this SDG and have a" : "project contributes to this SDG and has a",
			},
		})
	}
	return series
}
