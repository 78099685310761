import MediaCard from "../components/MediaCard/MediaCard"
import RetiredCerts from "../components/RetiredCerts"
import { usePageTitle } from "../hooks/TitleHook"
import { ARTICLE1_ENDPOINT, ARTICLE2_ENDPOINT, ARTICLE3_ENDPOINT, ARTICLE4_ENDPOINT } from "../util/Constants"

import SDGPanel from "../components/SDGPanel"
import LayoutWrapper from "../layoutBuilder/LayoutWrapper"
import LayoutItem from "../layoutBuilder/LayoutItem"
import DashboardKPICards from "./sub-components/DashboardKPICards"
import AllEmissionsByMode from "../components/Charts/Wireups/AllEmissionsByMode"
import { useFilterAtomValue } from "../hooks/FilterHooks"
import { useTheme } from "@mui/system"

const MiddleSection = () => {
	const theme = useTheme()
	const articleList = [
		{
			link: ARTICLE1_ENDPOINT,
			title: "Taking action on climate means protecting the future of humanity",
			body: "BlueHalo and Tasman Environmental Markets know whats at stake and hold a deep commitment...",
			image: require("../../src/assets/banners/article1.png"),
			tag: "Climate",
			duration: "2mins",
		},
		{
			link: ARTICLE2_ENDPOINT,
			title: "What's behind the price of a carbon offset?",
			body: "Why are some carbon offsets more expensive than others? There are very real reasons why – and knowing what’s happening...",
			image: require("../../src/assets/banners/article2.png"),
			tag: "Offsets",
			duration: "3mins",
		},
		{
			link: ARTICLE3_ENDPOINT,
			title: "Why due diligence on carbon offsets is critical for your strategy and brand.",
			body: "We’re not all experts on carbon offsets. So it’s important to choose a climate action partner...",
			image: require("../../src/assets/banners/article3.png"),
			tag: "Mindset",
			duration: "3mins",
		},
		{
			link: ARTICLE4_ENDPOINT,
			title: "Don't Tell Me",
			body: "The management consultant and author Peter Drucker once said that 'you can't manage what you don't measure’...",
			image: require("../../src/assets/banners/article4.png"),
			tag: "Mindset",
			duration: "3mins",
		},
	]

	const height100P = { height: "100%" }
	return (
		<>
			{articleList.map((v, index) => {
				return (
					<LayoutItem key={`article-${index}`} xs={6} sm={6} md={6} lg={3} xl={3} order={{ md: index + 1, lg: index < 2 ? index + 1 : index + 2 }} innerStyle={{ padding: 0, ...height100P }}>
						<MediaCard {...{ ...v, img: v.image }} imageHeight={130} />
					</LayoutItem>
				)
			})}

			<LayoutItem xs={12} sm={12} md={12} lg={6} xl={6} order={{ md: 5, lg: 3 }} innerStyle={height100P}>
				<SDGPanel />
			</LayoutItem>
			<LayoutItem xs={12} sm={12} md={12} lg={6} xl={6} order={{ md: 6, lg: 6 }} innerStyle={{ ...height100P, padding: theme.spacing(3, 2) }}>
				<RetiredCerts />
			</LayoutItem>
		</>
	)
}

const MainChart = () => {
	return (
		<LayoutItem>
			<AllEmissionsByMode />
		</LayoutItem>
	)
}

const Home = () => {
	usePageTitle("BlueHalo - Dashboard")
	const filterDataValue = useFilterAtomValue()

	return (
		<LayoutWrapper>
			<DashboardKPICards filterDataValue={filterDataValue} />
			<MainChart />
			<MiddleSection />
		</LayoutWrapper>
	)
}

export default Home
