import React from 'react'
import LoadingWrapper from '../Loading/LoadingWrapper'
import { SkeletonBarChart } from './SkeletonChart'

const ChartWrapper = ({ children, loaded, error }) => {
    return (
        <LoadingWrapper
            loaded={loaded}
            error={error}
            loadingComponent={<SkeletonBarChart />}
        >
            {children}
        </LoadingWrapper>
    )
}

export default ChartWrapper