import { Skeleton, Stack } from '@mui/material';
import React from 'react';

export const SkeletonBarChart = () => {
    return (

        <Stack spacing={2} sx={{ alignItems: "center", p: 2 }}>
            <Skeleton variant='text' width='60%' />
            <Skeleton variant='text' width='30%' />
            <Skeleton animation="wave" variant="rectangular" width='80%' height={260} />

            <Stack direction={'row'} spacing={3} sx={{ alignItems: "center" }}>
                <Skeleton animation="wave" variant="text" width={40} />
                <Skeleton animation="wave" variant="text" width={40} />
                <Skeleton animation="wave" variant="text" width={40} />
                <Skeleton animation="wave" variant="text" width={40} />
                <Skeleton animation="wave" variant="text" width={40} />
                <Skeleton animation="wave" variant="text" width={40} />
            </Stack>
        </Stack>

    );
};