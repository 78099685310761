import { Box } from "@mui/material"
import styles from "./index.module.scss"

export default function LoadingSpinner({ margin }) {
	return (
		<Box data-testid="spinner" style={{ margin }}>
			<Box data-testid="loading-spinner" className={styles.loaderContainer}>
				<span className={styles.loader}></span>
			</Box>
		</Box>
	)
}
