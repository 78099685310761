import { useQuery, useQueries } from "@tanstack/react-query"
import axios from "axios"
import { BACKEND_BASE_URL, BACKEND_BASE_API_KEY } from "../util/Constants"
import { isResponsesLoaded } from "../util/util"
import { useAccessToken } from "./AuthHooks"

const formatResponse = (response) => {
	const { status, data, error, isFetching } = response
	return {
		loaded: status === "loading" ? false : true,
		data: data?.data,
		error: error?.message,
		isFetching,
		status,
	}
}

const formatBulkResponse = (responses) => {
	const allResponses = responses.map((response) => {
		return formatResponse(response)
	})

	const erroredQuery = allResponses.find((item) => item.error)

	return {
		data: allResponses.map((item) => item.data),
		loaded: erroredQuery ? true : isResponsesLoaded(allResponses),
		error: erroredQuery ? erroredQuery.error : null,
	}
}

export const getAxiosConfig = (endpoint, body, method = "post", params) => {
	return {
		baseURL: BACKEND_BASE_URL,
		url: endpoint,
		method: method,
		headers: {
			Accept: "application/json",
			"Content-Type": "application/json",
		},
		params,
		data: body,
	}
}

const useAPIQuery = (keys = [], config, staleTime = Infinity) => {
	const access_token = useAccessToken()
	if (access_token) config["headers"]["Authorization"] = `Bearer ${access_token}`
	if (BACKEND_BASE_API_KEY) config["headers"]["X-Api-Key"] = BACKEND_BASE_API_KEY

	const response = useQuery(keys, () => axios(config), {
		enabled: Boolean(access_token || BACKEND_BASE_API_KEY),
		staleTime,
	})
	return formatResponse(response)
}

const useMultiAPIQuery = (queries) => {
	const response = useQueries({ queries })
	return formatBulkResponse(response)
}

export const useQueryEndpoint = (endpoint, body, method, params) => {
	const config = getAxiosConfig(endpoint, body, method, params)
	return useAPIQuery([endpoint, body, params], config)
}

export const useMultiQueryEndpoint = (queryData) => {
	const access_token = useAccessToken()

	const queryArray = queryData.map((item) => {
		const config = getAxiosConfig(item.url, item.body, item.method, item.params)
		if (access_token) config["headers"]["Authorization"] = `Bearer ${access_token}`
		if (BACKEND_BASE_API_KEY) config["headers"]["X-Api-Key"] = BACKEND_BASE_API_KEY
		return { queryKey: [item.url, item.body, item.params], queryFn: () => axios(config), enabled: Boolean(access_token || BACKEND_BASE_API_KEY), staleTime: Infinity }
	})

	return useMultiAPIQuery(queryArray)
}
