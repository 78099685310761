import { createSvgIcon } from "@mui/material/utils"
import * as React from "react"

export const IcCerts = createSvgIcon(
	<svg viewBox="0 0 25 25" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M7.22222 10.5C6.63837 10.5 6.16667 9.94141 6.16667 9.25C6.16667 8.55859 6.63837 8 7.22222 8C7.80608 8 8.27778 8.55859 8.27778 9.25C8.27778 9.94141 7.80608 10.5 7.22222 10.5ZM9.59722 9.25C9.59722 8.73047 9.95017 8.3125 10.3889 8.3125H17.7778C18.2165 8.3125 18.5694 8.73047 18.5694 9.25C18.5694 9.76953 18.2165 10.1875 17.7778 10.1875H10.3889C9.95017 10.1875 9.59722 9.76953 9.59722 9.25ZM9.59722 13C9.59722 12.4805 9.95017 12.0625 10.3889 12.0625H17.7778C18.2165 12.0625 18.5694 12.4805 18.5694 13C18.5694 13.5195 18.2165 13.9375 17.7778 13.9375H10.3889C9.95017 13.9375 9.59722 13.5195 9.59722 13ZM9.59722 16.75C9.59722 16.2305 9.95017 15.8125 10.3889 15.8125H17.7778C18.2165 15.8125 18.5694 16.2305 18.5694 16.75C18.5694 17.2695 18.2165 17.6875 17.7778 17.6875H10.3889C9.95017 17.6875 9.59722 17.2695 9.59722 16.75ZM7.22222 11.75C7.80608 11.75 8.27778 12.3086 8.27778 13C8.27778 13.6914 7.80608 14.25 7.22222 14.25C6.63837 14.25 6.16667 13.6914 6.16667 13C6.16667 12.3086 6.63837 11.75 7.22222 11.75ZM7.22222 18C6.63837 18 6.16667 17.4414 6.16667 16.75C6.16667 16.0586 6.63837 15.5 7.22222 15.5C7.80608 15.5 8.27778 16.0586 8.27778 16.75C8.27778 17.4414 7.80608 18 7.22222 18ZM3 6.75C3 5.36914 3.94505 4.25 5.11111 4.25H19.8889C21.0533 4.25 22 5.36914 22 6.75V19.25C22 20.6289 21.0533 21.75 19.8889 21.75H5.11111C3.94505 21.75 3 20.6289 3 19.25V6.75ZM4.58333 6.75V19.25C4.58333 19.5938 4.81951 19.875 5.11111 19.875H19.8889C20.1792 19.875 20.4167 19.5938 20.4167 19.25V6.75C20.4167 6.40469 20.1792 6.125 19.8889 6.125H5.11111C4.81951 6.125 4.58333 6.40469 4.58333 6.75Z"
		/>
	</svg>,
	"IcCerts"
)
