import { Link } from "@mui/material"
import { createRef, useEffect, useState } from "react"
import { useAccessToken } from "../../hooks/AuthHooks"
import { ErrorHandling } from "../Error"
import { trackEvent } from "../../monitoring/tracking"

function AuthenticatedLink({ url, filename, children }) {
	const [link, setLink] = useState(createRef(null))
	const access_token = useAccessToken()

	const handleAction = async () => {
		if (link.current.href) {
			return
		}
		if (!access_token) {
			return
		}

		const result = await fetch(url, {
			headers: { Authorization: `Bearer ${access_token}` },
		})

		const blob = await result.blob()
		const href = window.URL.createObjectURL(blob)

		if (blob.size > 0 && blob.type !== "application/problem+json") {
			link.current.download = filename
			link.current.href = href
			link.current.click()
			trackEvent("download certificate", { filename })
		} else {
			ErrorHandling("No data returned for URL")
		}
	}

	useEffect(() => {
		if (!link.current) setLink(createRef(null))
	})

	return (
		<Link className="txt-blue font-medium cursor-pointer txt-decoration-none" ref={link} onClick={handleAction}>
			{children}
		</Link>
	)
}

export default AuthenticatedLink
