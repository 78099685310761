import { useState } from "react"
import { Box, Paper, useTheme } from "@mui/material"
import { IcInfo } from "../../icon/IcInfo"
import styles from "./index.module.scss"

export default function IconTooltip({ tooltipContent }) {
	const theme = useTheme()
	const [hover, setHover] = useState(false)

	return (
		<>
			<Box className={`float-left cursor-help ${styles["tooltip-box"]}`} data-testid={"tooltip-icon"} onMouseLeave={() => setHover(false)} onMouseEnter={() => setHover(true)}>
				<IcInfo
					style={{
						height: "0.8rem",
						marginTop: theme.spacing(0.5),
						opacity: 0.3,
					}}
				/>
			</Box>
			<Paper className={`position-absolute z-index-100 bg-white visibility-${hover ? "visible" : "hidden"} w-100-percent h-100-percent`} data-testid={"tooltip-content"} elevation={0}>
				{tooltipContent}
			</Paper>
		</>
	)
}
